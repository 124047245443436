export default {
    meau: {
      nav: 'Главная страница',
      "nav1": "Покупка майнинг-узлов",
      "nav2": "Orion GPU Race",
      "nav3": "",
      nav3: 'DeepLink Software',
      nav3_1: 'Загрузка ПО',
      nav3_2: 'Лицензия на ПО',
      nav4: 'Майнинг трафика',
      "nav5": "Интернет-кафе",
      "nav6": "Блог",
      nav7: 'Docs'
    },
    cont1: {
      text1: 'Blockchain на основе программного обеспечения для удаленного управления',
      text2: 'Возможность подключения нескольких экранов для работы и игр в любое время и в любом месте',
      btn: 'Скачать сейчас',
      text3_1: 'Присоединиться',
      text3_2: 'сообщество',
      text3_3: 'чтобы получить награды DeepLink Coin!'
    },
    cont2: {
      text1: 'DeepLink',
      text2: 'Положитесь на возможности игровых технологий с малой задержкой, чтобы все устройства были в пределах досягаемости.',
      text3: 'Основные компетенции и технические преимущества',
      text4: 'Основываясь на основных возможностях облачных игр, опыт намного превосходит традиционное дистанционное управление!',
      leaf1: {
        text1: 'Очень плавный, высокая производительность',
        text2: 'Бюджетный',
        text3: 'Ведущее в отрасли решение для кодеков с минимальным потреблением производительности и ресурсов при той же скорости кодирования',
        text4: 'одноранговая связь: поддерживает ipv6 с пропускной способностью NAT до 95% и нулевым использованием полосы пропускания.'
      },
      leaf2: {
        text1: 'Высокая частота кадров, низкая задержка',
        text2: '',
        text3: "С задержкой LAN <15 мс и поддержкой 4K 240 Гц обзор WAN также находится на высшем уровне в мире."
      },
      leaf3: {
        text1: 'Высокое разрешение',
        text2: '',
        text3: 'Интеллектуальные алгоритмы улучшают просмотр с той же скоростью передачи данных и восстанавливают наиболее реалистичные цветовые характеристики.'
      },
      leaf4: {
        text1: 'Высокая стабильность',
        text2: '',
        text3: 'Стратегии предотвращения потери пакетов и предотвращения перегрузки, поддерживаемые большими объемами данных, позволяют быстро реагировать на потерю пакетов данных и перегрузку.'
      },
      leaf5: {
        text1: 'Высокая безопасность',
        text2: '',
        text3: 'Все данные шифруются DTLS1.2 с AES256.'
      }
    },
    cont3: {
      text1: "Так гладко, как будто управляешь локальным устройством",
      text2: 'Применяя десятки методов оптимизации в трех основных областях, DeepLink способен поддерживать высокую частоту кадров, несмотря на 5%-ную потерю пакетов и задержку в сети 100 мс*. Соответствие требованиям всех типов сетевых сред.',
      li1: {
        text1: 'Слабая технология противодействия сети',
        text2: 'Стратегия FEC против потери упаковки',
        text3: 'Прогноз пропускной способности',
        text4: 'Стратегия кодирования SVC'
      },
      li2: {
        text1: 'Методы оптимизации передачи',
        text2: 'Прогнозирование отпечатков портов',
        text3: 'Поддерживает IPV6',
        text4: 'UPNP',
        text5: 'Интеллектуальная маршрутизация'
      },
      li3: {
        text1: 'Технология обработки дисплея',
        text2: 'Стратегия адаптации частоты кадров',
        text3: 'Предсказание кино',
        text4: 'Стратегия обработки джиттера',
        text5: 'Рендеринг HD с низким кодированием AI',
        text6: 'Динамическое программное обеспечение виртуальной реальности'
      },
      desc3: 'LAN < 5 мс - Междугородная сеть < 10 мс - Межобластная сеть < 30 мс'
    },
    cont4: {
      text1: 'Супер четкое качество графики',
      text2: 'Ультра HD-разрешение',
      text3: 'Поддерживается 1,07 миллиарда цветов',
      text4: '4:4:4 неиспорченное качество изображения',
      text5: "DeepLink всегда использует более требовательные показатели качества изображения для достижения качества изображения, которое намного превосходит другие традиционные удаленные технологии, с удаленным искажением изображения ниже предела распознавания человеком, что полностью поддерживает потребность творческой индустрии в точном отображении изображения.",
      text6: '*PSNR относится к пиковому отношению сигнал-шум, ниже 20 дБ искажение изображения высокое, 20–30 дБ — искажение изображения среднее, 30–40 дБ — искажение изображения низкое, выше 40 дБ — искажение изображения чрезвычайно низкое и незаметное для невооруженного глаза.'
    },
    cont5: {
      text1: 'Широкая периферийная поддержка',
      text2: 'DeepLink обеспечивает удаленную поддержку профессиональных аксессуаров, таких как цифровые доски и джойстики, для творческой индустрии, а также оптимизирует использование программного обеспечения для творчества, такого как PS, Zbrush и Unity, чтобы обеспечить единообразное использование инструментов творческого производства как в удаленных, так и в местных штатах.'
    },
    cont6: {
      text1: 'Множественные гарантии - минималистский подход',
      block1: {
        title1: 'Безопасные учетные записи',
        desc1: 'Многоуровневый механизм защиты аккаунта для вашего спокойствия',
        title2: 'Безопасное соединение',
        desc2: 'Защита протокола DTLS1.2, доступ только с авторизацией',
        title3: 'Безопасные данные',
        desc3: '256-битное шифрование AES, безопасное во время передачи',
        title4: 'Безопасные устройства',
        desc4: 'Только соответствующий человек увидит соответствующее устройство в соответствии с вашими настройками'
      },
      block2: {
        title1: 'Многочисленные гарантии безопасности',
        desc1: 'Защита каждого соединения',
        title2: 'Легко использовать',
        desc2: 'Легко начать'
      },
      block3: {
        title1: 'Легкий для скачивания',
        desc1: "Для клиентской части DeepLink это всего около 10 млн.",
        title2: 'Легко учить',
        desc2: 'Процесс настройки можно завершить, следуя инструкциям',
        title3: 'Легко использовать',
        desc3: 'Удаленное управление любым устройством в пределах ваших прав с помощью мыши'
      }
    },
    cont7: {
      text1: 'Предлагает решения для удаленного управления',
      block1: {
        word1: 'Образовательная индустрия',
        word2: 'Игровая индустрия',
        word3: 'Креативная и дизайнерская индустрия',
        word4: 'ИТ-индустрия',
        word5: 'Бизнесмены и женщины в пути',
        word6: 'Соавторы по всему миру',
        word7: 'Удаленное обслуживание IT O&M',
        word8: 'Отдельные пользователи и геймеры'
      },
      block2: {
        text1: 'Поддержка сценариев удаленного управления в различных отраслях',
        text2: 'Основываясь на реальных потребностях компаний в различных отраслях, мы помогаем им достичь своих бизнес-целей энергоэффективным способом.',
        text3: 'Предлагает политики персонализации и шифрования, гибкую систему безопасности',
        text4: "Сотрудники компании могут получить доступ к ресурсам компании в любое время и из любого места на основе их фактических разрешений."
      },
      block3: {
        text1: 'Охватывает широкий спектр потребностей пользователей',
        text2: 'Независимо от того, решите ли вы использовать его для своего внутреннего персонала,',
        text3: 'или для внешних клиентов, сотрудников или даже для личного использования.',
        text4: 'Наши возможности дистанционного управления могут служить вам на основных платформах и устройствах,',
        text5: 'предлагая альтернативный, простой и умный способ решения проблем.'
      }
    },
    footer: {
      link: 'Узнать больше о',
      community: 'Следите за нашим сообществом',
      tip: 'Наши сообщества в: Twitter, Telegram, Reddit, Medium, Discord, TikTok, YouTube.',
      text: 'Протокол DeepLink представляет собой децентрализованный протокол облачного игрового сервиса на базе ИИ, который объединяет технологии ИИ, GPU и блокчейна для предоставления технологии рендеринга игр с ультранизкой задержкой. Протокол предлагает экономически эффективные решения для облачных игр для таких отраслей, как игры AAA, облачные интернет-кафе и отели для киберспорта',
      meau1: 'О нас',
      meau2: 'Продукты',
      meau3: 'Блокчейн',
      meau4: 'Важные события по аирдропам',
      btn5: 'Windows SDK',
      meau3_text1: 'Браузер DLC BSC-цепи',
      meau3_text2: 'Браузер DLC DBC-цепи',
      meau3_text3: 'Кошелек основной сети DBC',
      meau4_text1: 'Аирдроп Bybit на 300,000U DLC',
      meau4_text2: 'Аирдроп Okx на 360,000U DLC',
      meau4_text3: 'Аирдроп Binance на 300,000U DLC',
    },
    reward: {
      title1: 'Узел (узлы) DeepLink',
      desc1: 'Иметь доступ к наградам, например. Токен DeepLink, Crown NFT, сила управления сообществом.',
      title2: 'Получить Genesis Node NFT',
      text1: "Чем больше пользователей в сети DeepLink, тем больше требуется вычислений и трафика. Узлы Genesis предоставляют инфраструктуру для сети DeepLink, тем самым децентрализуя и помогая DeepLink расти быстрее.",
      text2: 'Приобретая Genesis Node NFT, вы получаете возможность получить вознаграждение за свой вклад в сеть.',
      text3: 'Это может включать в себя токен DeepLink, Crown NFT и возможности управления сообществом.',
      text4_1: 'Есть вопросы? Приходите поговорить с нами на',
      text4_2: 'и',
      text4_3: '',
      btntext: 'Купи сейчас',
      text5: 'На каждые 1000 проданных Genesis Nodes NFT цена увеличивается на 1000 долларов США.',
      text6_1: 'Количество узлов',
      text6_2: 'каждый NFT поддерживает в настоящее время',
      text7: 'Общее количество узлов Genesis NFT',
      text8: 'Выпуск ограничен навсегда',
      text9: 'Начальная цена',
      text10_1: 'Максимальное количество узлов',
      text10_2: 'Поддерживается по NFT',
      text11: 'Максимальная цена',
      text12: 'Что такое узел Genesis?',
      text13: 'Узлы Genesis выполняют основные задачи сетевых вычислений и переадресации трафика DeepLink, а DeepLink позволяет большему количеству людей участвовать в получении дохода с помощью модели майнинга.',
      text14: 'Сколько всего токенов было дано узлам Genesis в качестве вознаграждения?',
      text15: '30% от общего количества токенов, т.е. 30 миллиардов токенов DeepLink',
      text16: 'Что такое Genesis Node NFT',
      text17: 'Участвовать в майнинге могут только те, у кого есть Genesis Node NFT.',
      text18: 'Как распределяется доход от продажи Genesis Node NFT?',
      text19: '0% выручки инвестируются в децентрализованные пулы вознаграждения за ликвидность (например, токен DeepLink/USDC для Uniswap, Sushiswap), а 50% поступает в операционную компанию DeepLink в качестве выручки.',
      text20: 'Каков конкретный механизм майнинга?',
      text21: 'Общее вознаграждение составляет 5 миллиардов долларов в год, уменьшается вдвое каждые 3 года, 10% дохода DeepLink Crown NFT используется для выкупа токенов DeepLink, которые добавляются к общему пулу вознаграждений перед каждым делением пополам в рамках 50% вознаграждения. Токен, присуждаемый на все узлы за 3 года. Например, через 3 года 10% доходов DeepLink Crown NFT могут выкупить 12 миллиардов токенов DeepLink, тогда общий оставшийся пул вознаграждений станет: 12 миллиардов + 15 миллиардов, всего 27 миллиардов, и общее годовое вознаграждение от вторых 3 -лет составляет 27 миллиардов * 50% * 1/3 = 4,5 миллиарда.',
      text22: 'Каковы требования к узлам Genesis?',
      text23: "Каждая машина уже должна быть в сети DeepBrain Chain. DeepBrain Chain — это децентрализованная высокопроизводительная вычислительная сеть, и машины в DeepBrain Chain заложили DBC, что может гарантировать стабильность машин. Для получения дополнительной информации о цепочке DeepBrain:",
      text24: 'Каковы другие преимущества владения Genesis Node NFT?',
      details: {
        text1: "1. Владение определенным количеством NFT Genesis Node дает вам общее право агентства продавать NFT DeepLink в стране (1 NFT необходим на каждые 3 миллиона человек в соответствии с долей населения страны);",
        text2: '2. Раздача монет DeepLink;',
        text3: '3. Раздача токенов инкубируемого игрового проекта DeepLink;',
        text4: '4. Право голосовать за то, какие игры будут запущены онлайн;',
        text5: '5. Скидка на покупку игры NFT;',
        text6: '6. Скидка на покупку прав и часов на облачную игру;',
        text7: '7. Другие права на участие в управлении сообществом.'
      },
      question: {
        text1_1: 'Что такое узел Genesis?',
        text1_2: 'Узлы Genesis выполняют основные задачи сетевых вычислений и переадресации трафика DeepLink, а DeepLink позволяет большему количеству людей участвовать в получении дохода с помощью модели майнинга.',
        text2_1: 'Сколько всего токенов было дано узлам Genesis в качестве вознаграждения?',
        text2_2: '10% от общего количества токенов, т.е. 10 миллиардов токенов DeepLink',
        text3_1: 'Что такое Genesis Node NFT',
        text3_2: 'Участвовать в майнинге могут только те, у кого есть Genesis Node NFT.',
        text4_1: 'Как распределяется доход от продажи Genesis Node NFT?',
        text4_2: '0% выручки инвестируются в децентрализованные пулы вознаграждения за ликвидность (например, токен DeepLink/USDC для Uniswap, Sushiswap), а 50% поступает в операционную компанию DeepLink в качестве выручки.',
        text5_1: 'Каков конкретный механизм майнинга?',
        text5_2: 'Общее вознаграждение составляет 5 миллиардов долларов в год, уменьшается вдвое каждые 3 года, 10% дохода DeepLink Crown NFT используется для выкупа токенов DeepLink, которые добавляются к общему пулу вознаграждений перед каждым делением пополам в рамках 50% вознаграждения. Токен, присуждаемый на все узлы за 3 года. Например, через 3 года 10% доходов DeepLink Crown NFT могут выкупить 12 миллиардов токенов DeepLink, тогда общий оставшийся пул вознаграждений станет: 12 миллиардов + 15 миллиардов, всего 27 миллиардов, и общее годовое вознаграждение от вторых 3 -лет составляет 27 миллиардов * 50% * 1/3 = 4,5 миллиарда.',
        text6_1: 'Каковы требования к узлам Genesis?',
        text6_2: "Каждая машина уже должна быть в сети DeepBrain Chain. DeepBrain Chain — это децентрализованная высокопроизводительная вычислительная сеть, и машины в DeepBrain Chain заложили DBC, что может гарантировать стабильность машин. Для получения дополнительной информации о цепочке DeepBrain:",
        text7_1: 'Каковы другие преимущества владения Genesis Node NFT?',
        text7_2: `1. Владение определенным количеством NFT Genesis Node дает вам общее право агентства продавать NFT DeepLink в стране (1 NFT необходим на каждые 3 миллиона человек в соответствии с долей населения страны);2. Раздача монет DeepLink;3. Раздача токенов инкубируемого игрового проекта DeepLink;
        4. Право голосовать за то, какие игры будут запущены онлайн;5. Скидка на покупку игры NFT;6. Скидка на покупку прав и часов на облачную игру;7. Другие права на участие в управлении сообществом.`,
      }
    },
    nft: {
      cont: {
        title1: 'Корона NFT',
        desc1: 'С этим NFT вы можете получить 1 год доступа ко всем корпоративным функциям программного обеспечения DeepLink.',
        desc2: 'NFT неактивен после покупки, когда этот NFT необходимо использовать, его можно активировать в программном обеспечении DeepLink, когда NFT активирован',
        desc3: 'Начинается отсчет 1 года использования. 10% дохода NFT от программного обеспечения DeepLink инвестируется в вознаграждение за узлы,',
        // desc4: '40% выручки инвестируется в пул вознаграждений за ликвидность, а 50% — выручка операционной компании DeepLink.'
        desc4: '40% доходов будут уничтожены для увеличения стоимости токена DLC.'
      },
      enabl: {
        title1: 'NFT Права',
        // desc1: '1 год доступа ко всем корпоративным функциям программного обеспечения DeepLink',
        desc1: 'Иметь доступ к расширенным функциям программного обеспечения DeepLink',
        desc2: 'Раздача монет DeepLink',
        desc3: 'Раздача токенов инкубируемого игрового проекта DeepLink',
        desc4: 'Право голоса за то, какие игры будут запущены онлайн',
        desc5: 'Скидка на покупку игры NFT',
        desc6: 'Скидка при покупке прав на облачную игру и часов',
        desc7: 'Другие права на участие в управлении сообществом'
  
      },
      edition: {
        title1: 'Профессиональная версия',
        title2: 'Командное версия',
        tip: 'Корона NFT',
        btn: 'Купи сейчас',
        title3: 'Профессиональный VS Команды версия',
        desc: 'Доступ к определенным функциям',
        list: {
          li1_1: 'Содержание',
          li1_2: 'Базовая версия',
          li1_3: 'Профессиональная версия',
          li1_4: 'Команды версия',
          li2_1: 'Лучшее качество изображения',
          li3_1: 'Серверная комната переадресации',
          li3_2: 'Небольшое количество',
          li3_3: 'Множество',
          li4_1: 'Пропускная способность пересылки',
          li4_2: 'Максимум 4М',
          li4_3: 'Максимум 50M',
          li4_4: 'Неограниченный',
          li5_1: 'Сквозная задержка',
          li5_2: 'Минимум<40ms',
          li5_3: 'Минимум <20ms',
          li6_1:'Количество устройств, которые могут инициировать удаленное управление одновременно',
          li7_1:'Количество устройств',
          li8_1:'Удаленный рабочий стол',
          li9_1:'Удаленная камера',
          li10_1:'Удаленный SSH',
          li11_1:'Экран конфиденциальности',
          li12_1:'Виртуальный монитор',
          li13_1:'Игровая клавиатура',
          li14_1:'Многопоточность',
          li15_1:'444 настоящих цвета',
          li16_1:'Запись скриншотов',
          li17_1:'Двойной экран на двойной экран',
          li18_1:'Ручка',
          li19_1:'Управление группами пользователей/устройств',
          li20_1:'Единый вход SSO',
          li21_1:'Единая авторизация управления',
          li22_1:'Массовое развертывание',
          li23_1:'Аудит поведения сотрудников',
          li24_1:'Водяной знак',
          li25_1:'Цена',
          li26_1: 'Сеансы рабочего стола на одном компьютере',
          li27_1: 'передача файла',

          new_text1: 'Если один и тот же адрес кошелька вошел в систему сразу на нескольких устройствах, только одно из устройств может инициировать удаленное управление',
          new_text2: 'Машина может открыть только два окна с дистанционным управлением',
        }
      }
    },
    cloudInternet: {
      title: 'Как стать поставщиком облачного решения для интернет-кафе',
      text: 'В настоящее время основные страны распределения интернет-кафе по всему миру включают Южную Корею (6 000), Китай (40 000), Бразилию (10 000), Индонезию (20 000), Филиппины (20 000), Вьетнам (15 000), Турцию (5 000) и другие. Кроме того, есть такие страны, как Индия, Мексика, Ближний Восток, Южная Америка, Африка, Европа и другие. В мире более 150 000 интернет-кафе. Однако отрасль интернет-кафе имеет историю 20 лет и стала традиционной отраслью. Период окупаемости увеличился до 3-4 лет, и он сталкивается с различными рисками. Например, пандемия COVID-19 привела к закрытию большого числа интернет-кафе. Поэтому отрасль интернет-кафе нуждается в новом решении для сокращения периода окупаемости, снижения рисков и повышения эффективности управления. Таким образом, возникает облачное решение для интернет-кафе.',
      cont2: {
        text1: 'Что такое ',
        text2: 'решение облачного интернет-кафе?',
        text3: 'Решение облачного интернет-кафе - это новый тип модели обслуживания интернет-кафе с технологией рендеринга с ультранизкой задержкой. В облачном интернет-кафе игры и приложения не устанавливаются непосредственно на локальные компьютеры, а размещаются на облачных серверах с GPU. Пользователи получают доступ и играют в игры через облачные услуги, не требуя при этом высокопроизводительного локального оборудования.',
        block1: {
          text1: 'Снижение требований к локальному оборудованию',
          text2: 'Поскольку игры и приложения работают на облачных серверах с GPU, локальным компьютерам не требуется высокопроизводительных GPU или больших объемов хранения. Это снижает инвестиции в оборудование интернет-кафе и затраты на его обслуживание.'
        },
        block2: {
          text1: 'Мгновенный доступ к последним играм',
          text2: 'Пользователи могут мгновенно получать доступ к последним играм без ожидания загрузки и установки. Все обновления и обслуживание игр проводятся в облаке, обеспечивая пользователям удобство и эффективность.'
        },
        block3: {
          text1: 'Игровой опыт высокой производительности',
          text2: 'Локальные мониторы нужно только оборудовать мини-ПК (около 100 долларов), и облачные интернет-кафе могут обеспечить высокопроизводительный игровой опыт. Это потому, что вычислительная работа игры выполняется на удаленных высокопроизводительных серверах с GPU.'
        },
        block4: {
          text1: 'Экономия места и энергии',
          text2: 'Компьютеры с низкой конфигурацией занимают меньше места и потребляют меньше энергии, что помогает снизить операционные расходы интернет-кафе.'
        },
        block5: {
          text1: 'Простое управление и обслуживание',
          text2: 'Администраторы интернет-кафе могут легко управлять программным обеспечением и играми, потому что вся информация централизованно управляется в облаке. Это снижает локальную обслуживание и потенциальные технические проблемы.'
        }
      },
      cont3: {
        text1: 'Cloud Cybercafe Glowsteam в Южной Корее',
        text2: 'Фактическая рабочая среда облачного киберкафе'
      },
      cont4: {
        text1: 'Решение облачного киберкафе может помочь инвесторам киберкафе',
        text2: 'Увеличить чистую прибыль в 3 раза и сократить период окупаемости на 40%',
        text3: 'Нажмите, чтобы скачать сравнительные данные'
      },
      cont5: {
        text1: 'Какова прибыль для поставщиков решений облачного киберкафе?',
        text2: 'Если прибыль рассчитывается как 12% от доходов с одной киберкафе-машины, обслуживая в общей сложности 1000 киберкафе с 100 000 машинами, каждая машина используется в среднем 10 часов в день, и средняя стоимость одной машины в час составляет 0,8 доллара, то 12% примерно равно 0,1 доллара.',
        text3: 'Годовая прибыль поставщика решений облачного киберкафе составляет',
        text4: '100 000 * 10 * 0,1 * 365 = 36,5 миллиона долларов',
        text5: 'Как стать поставщиком решений облачного киберкафе',
        text6: 'На основе технологий DeepBrainChain и DeepLink южнокорейская компания HYCONS разработала полное решение для облачного киберкафе. DeepBrainChain предоставляет вычислительную мощность GPU, DeepLink предлагает технологию рендеринга с ультранизкой задержкой, а HYCONS предоставляет всё решение. Аналогично, если сравнить с автомобилем, DeepBrainChain предоставляет двигатель, DeepLink предоставляет автономную систему вождения, а HYCONS предоставляет полное решение для автомобиля. Местные поставщики решений в разных странах должны только интегрировать свои локальные платежные системы в HYCONS, чтобы предоставить услуги местным киберкафе, и поставщики решений могут устанавливать собственные бренды. HYCONS действует только как технологический сервис-провайдер без раскрытия своего бренда.',
        text7: 'Для получения дополнительной информации, пожалуйста, свяжитесь с:',
        text8: "service{'@'}deepbrainchain.org"
      },
      cont6: {
        title: 'Скачать связанные с облачным киберкафе материалы',
        text1: 'Введение в HYCONS',
        text2: 'Анализ прибыли от решения облачного киберкафе',
        text3: 'Диаграмма архитектуры центра обработки данных облачного киберкафе',
        text4: 'Руководство по тестированию и установке решения облачного киберкафе',
        text5: 'Введение в протокол DeepLink'
      }
    },
    reward2: {
      desc: {
        title1: 'Узлы DeepLink',
        text1: 'Только владельцы узлов могут участвовать в майнинге трафика CPU и майнинге на персональных GPU машинах. Общая награда составляет 12% токенов DLC, из которых 4% предназначено для узлов трафика CPU, и 8% для узлов персональных GPU. Общее количество токенов составляет 12 миллиардов. Производительность каждого узла зависит от фактического количества проданных узлов. Если все узлы проданы, каждый узел произведет 100,000 DLC. Если продано только 10% узлов, каждый узел произведет 1 миллион DLC.',
        title2: 'Общее количество узлов: 120,000 дополнительная эмиссия не предусмотрена',
        text2: 'Каждый узел получает дополнительный аирдроп',
        text3: 'Минимальное количество DLC, добываемое каждым узлом',
        text4: 'Начальная цена',
        text5: 'Максимальная цена'
      },
      btn_text: 'Купить узлы',
      table: {
        tier: 'Уровень',
        license: 'Цена лицензии на узел (USDT)',
        total_num: 'Всего лицензий',
        total_usdt: 'Всего USDT',
        buy: 'Купить'
      },
      intro: {
        title: 'Введение в майнинг узлов',
        text1_1: 'Чем больше пользователей сети DeepLink, тем больше требуется сетевого трафика и вычислительных мощностей, что приводит к необходимости большего количества машин для трафика CPU и машин для вычислений на GPU, чтобы обеспечить инфраструктуру для сети DeepLink, достигая децентрализации и способствуя более быстрому росту DeepLink. Приобретая лицензию на узел, вы получаете возможность участвовать в майнинге и зарабатывать вознаграждения. Есть вопросы? Пожалуйста,',
        text1_2: 'и',
        text1_3: 'общайтесь с нами выше.',
        text2: 'Каждый узел выпускается в виде NFT и дополнительно получает 30 000 DLC в виде эйрдропа на цепочке DBC EVM. 20% раздаваемых DLC разблокируются сразу, а 80% — в течение 8 месяцев.\n(Как проверить токен DLC: откройте кошелек, выберите сеть DBC EVM, добавьте токен и введите адрес контракта: 0x6f8F70C74FE7d7a61C8EAC0f35A4Ba39a51E1BEe)',
        text3: 'Узлы не могут быть перепроданы другим людям до 31 октября 2024 года.',
        text4: '25% добытых токенов разблокируются сразу, остальные 75% разблокируются линейно в течение 150 дней.',
        text5: 'Если майнинг не начнется в течение 10 дней после листинга, награды будут распределяться через аирдропы, которые будут проводиться каждые 7 дней. Правила аирдропа такие же, как и правила добычи, с общим ежедневным выпуском 4,1 миллиона DLC, равномерно распределенными в зависимости от общего количества проданных узлов. 25% разблокируется сразу, а 75% разблокируется линейно в течение 150 дней. Если майнинг уже начался, для получения вознаграждений потребуются соответствующие машины.',
        text6: 'При покупке более 20 узлов операции узлов могут быть делегированы. Инвесторам, которые покупают более 1000 узлов по отдельности, будет предоставлен полный набор поддержки для работы узлов, а также возможность стыковаться с кооперативным оператором для помощи в управлении узлами.',
        text7: 'Одна виртуальная машина может поддерживать 20 узлов NFT, а доход увеличивается в 20 раз.',
        text8: 'Приобретите узел DeepLink и примите участие в соревновании по вычислительной мощности Orion GPU с призовым фондом 9 миллионов долларов: '
      },
      question: {
        ques1: 'Сколько всего узловых NFT продается?',
        ans1: 'Всего 120,000',
        ques2: 'Сколько стоит каждый узловой NFT?',
        ans2: 'Всего 10 уровней, с ценами от 30U до 155U, продается на официальном сайте до распродажи',
        ques3: 'Покупка узлового NFT является одноразовой?',
        ans3: 'Да, покупка узла является одноразовой, дополнительных платежей в будущем не требуется',
        ques4: 'Есть ли дополнительные аирдропы при покупке узлового NFT?',
        ans4: 'Да, каждый владелец узла получает эйрдроп в размере 30 000 DLC. 20% раздаваемых DLC разблокируются сразу, а 80% — в течение 8 месяцев',
        ques5: 'Каков конкретный механизм майнинга?',
        ans5: 'Общая награда составляет 12 миллиардов DLC. Общий доход для трафик-узлов составляет 4 миллиарда DLC, и общий доход для узлов персональных GPU машин составляет 8 миллиардов DLC. Приобретя узловой NFT, вы можете одновременно участвовать в обоих видах майнинга. В течение первых 4 лет награда составляет 1,5 миллиарда DLC в год, с уменьшением вдвое каждые 4 года. Общий дневной бонус составляет 4,11 миллиона DLC, распределяемых только среди владельцев узловых NFT. 25% добытых токенов разблокируются сразу, а 75% разблокируются линейно в течение 150 дней',
        ques6: 'Может ли приобретенный узловой NFT участвовать в майнинге на машинах с трафиком CPU и на персональных машинах с GPU?',
        ans6: 'Да',
        ques7: 'Что такое узел машины с трафиком CPU?',
        ans7: 'Узел машины с трафиком CPU выполняет основную задачу по вычислениям и передаче трафика в сети DeepLink. С помощью модели майнинга DeepLink позволяет большему количеству людей участвовать и получать вознаграждения. Общий доход для трафик-узлов составляет 4 миллиарда DLC',
        ques8: 'Какова общая награда для машин с трафиком в первый год?',
        ans8: 'Награда за первый год составляет 500 миллионов DLC, с ежедневной наградой около 1,37 миллиона DLC',
        ques9: 'Какое соотношение между доходами каждого узла машины с трафиком CPU и его пропускной способностью?',
        ans9: 'Базовая пропускная способность составляет 10Мб восходящего трафика. Значение пропускной способности для 10Мб восходящего трафика составляет 10. Пропускная способность действительна только в кратных 10, например, пропускная способность 45Мб восходящего трафика рассчитывается как 40. Пропускная способность увеличивается на 30% в зависимости от фактического использования. В конечном итоге доходы распределяются в зависимости от фактического значения пропускной способности',
        ques10: 'Требования к конфигурации узловых машин с трафиком высоки? Есть ли информация о конфигурации? Как участвовать в майнинге?',
        ans10: 'Требования к конфигурации невысокие. vCPU 1C + 2Гб памяти + 30G жесткого диска. Аренда на AWS стоит около 10 долларов в месяц, пропускная способность оплачивается в зависимости от фактического использования. После аренды виртуальной машины на AWS установите программу майнинга узлового трафика DeepLink, создайте адрес кошелька, переведите узловой NFT в кошелек и активируйте функцию майнинга, чтобы участвовать в майнинге',
        ques11: 'Что такое узел персональной машины с GPU?',
        ans11: 'Индивидуальные пользователи могут предложить свои GPU компьютеры для участия в майнинге. Машины с GPU предоставляют инфраструктурные услуги для облачного гейминга в рамках протокола DeepLink. Общий доход для узлов персональных машин с GPU составляет 8 миллиардов DLC',
        ques12: 'Какова общая награда для узлов персональных машин с GPU в первый год?',
        ans12: 'Награда за первый год составляет 1 миллиард DLC, с ежедневной наградой около 2,74 миллиона DLC',
        ques13: 'Каковы требования к конфигурации узлов персональных машин с GPU? Как участвовать в майнинге?',
        ans13: 'Требования к конфигурации машин с GPU: частота CPU не ниже 3.0ГГц, GPU из серии Nvidia Geforce: 20xx, 30xx, 40xx, и не менее 16Гб памяти'
      }
    },
    BandWidth: {
      "title": "Правила майнинга по трафику и пропускной способности",
      "text1": "Всего в майнинге трафика и пропускной способности предусмотрено 4 миллиарда DLC в качестве награды, по 500 миллионов DLC в год в течение первых четырех лет, с последующим уменьшением вдвое каждые четыре года.",
      "cont2": {
        "title": "Правила участия",
        "text1_1": "Необходимо владеть NFT узлом (каждый узел дает эирдроп в 30,000 DLC, узнать больше о узлах: ",
        "text1_2": "). Один CPU-машин может содержать несколько узлов, количество токенов награды пропорционально количеству узлов. Максимальное количество NFT узлов, которое может содержать одна машина, — 20.",
        "text2_1": "Минимальные требования к серверам CPU: \n Параметры сервера вычислений должны соответствовать минимальным требованиям",
        "text3_1": "CPU",
        "text3_2": "vCPU 1C",
        "text4_1": "Память",
        "text4_2": "2G",
        "text5_1": "Жесткий диск:",
        "text5_2": "30G жесткий диск",
        "text6_1": "Сетевая пропускная способность:",
        "text6_2": "Базовая пропускная способность — 10Mb загрузки; рекомендуется увеличивать CPU на 1C и память на 2G на каждые 200M увеличения пропускной способности"
      },
      cont3: {
        title: 'Правила регионов добычи трафика',
        text: 'Добыча трафика будет разделена на около 130 регионов по всему миру, каждому региону будут назначены разные общие суммы наград DLC, формула региональных наград: региональные награды = региональное значение * ежедневное вознаграждение / общее региональное значение',
        text1: 'Список регионов следующий:',
        text2: 'Азия',
        text3: 'Америка',
        text4: 'Европа',
        text5: 'Африка',
        text6: 'Океания',
        li_other: 'Другие',
        li1: 'Китай',
        li1_1: 'Северный Китай',
        li1_2: 'Северо-Восточный Китай',
        li1_3: 'Восточный Китай',
        li1_4: 'Центральный Китай',
        li1_5: 'Южный Китай',
        li1_6: 'Юго-Западный Китай',
        li1_7: 'Северо-Западный Китай',
        li1_8: 'Тайвань, Китай',
        li1_9: 'Гонконг, Китай',
        li2: 'Индия',
        li2_1: 'Уттар-Прадеш',
        li2_2: 'Махараштра',
        li2_3: 'Бихар',
        li2_4: 'Индонезия',
        li2_5: 'Пакистан',
        li2_6: 'Бангладеш',
        li2_7: 'Япония',
        li2_8: 'Филиппины',
        li2_9: 'Вьетнам',
        li2_10: 'Турция',
        li2_11: 'Таиланд',
        li2_12: 'Южная Корея',
        li2_13: 'Малайзия',
        li2_14: 'Саудовская Аравия',
        li2_15: 'Объединенные Арабские Эмираты',
  
        li3: 'Соединенные Штаты',
        li3_1: 'Калифорния',
        li3_2: 'Техас',
        li3_3: 'Флорида',
        li3_4: 'Нью-Йорк',
        li3_5: 'Пенсильвания',
        li3_6: 'Иллинойс',
        li3_7: 'Огайо',
        li3_8: 'Джорджия',
        li3_9: 'Мичиган',
        li3_10: 'Северная Каролина',
        li3_11: 'Другие регионы США',
        
        li4_1: 'Мексика',
        li4_2: 'Канада',
        li4_3: 'Бразилия',
        li4_4: 'Колумбия',
        li4_5: 'Аргентина',
        
        li5: 'Россия',
        li5_1: 'Москва',
        li5_2: 'Санкт-Петербург',
        li5_3: 'Прочие регионы России',
        li5_4: 'Германия',
        li5_5: 'Великобритания',
        li5_6: 'Франция',
        li5_7: 'Италия',
        li5_8: 'Испания',
        li5_9: 'Нидерланды',
        li5_10: 'Швейцария',
        
        li6_1: 'Нигерия',
        li6_2: 'Египет',
        li6_3: 'Южная Африка',
        
        li7_1: 'Австралия',
      },
      cont5: {
        title: 'Механизм вознаграждения (Основан на общедоступной блокчейн-системе DBC AI, совместимой с EVM DLC, URL браузера: https://dbcscan.io/)',
        text1: 'В течение первых четырех лет годовая награда за майнинг составляет 500 миллионов DLC, ежедневно - 1,369 миллиона DLC. В каждом регионе награды в DLC распределяются в соответствии с весовыми коэффициентами. Если в регионе нет машин, то награды DLC этого региона автоматически уничтожаются и никому не распределяются.',
        text2: 'Общее количество токенов, награждаемых каждый день в каждом регионе в этой системе, фиксировано. Награда, которую получает машина, зависит от пропускной способности машины, эффективного времени майнинга Y и количества заложенных токенов. Чем больше токенов заложено, тем больше награда, но она растет в нелинейной зависимости. Основа награды одинакова для 0-10 000 монет; награды связаны с функцией при превышении 10 000 монет.',
        text3: 'Если трафик машины используется, значение вычислительной мощности увеличивается на 30%, и количество NFT узлов является множителем вычислительной мощности. В настоящее время каждая машина может содержать до 20 NFT узлов.',
        text4: 'Только машины с NFT узлами имеют право на онлайн-вознаграждения.',
        text5: 'В настоящее время одна машина может связать до 20 NFT узлов, при этом большее количество NFT приводит к большему вознаграждению, показывая линейную зависимость.',
        text6: 'В течение первых 300 дней одна машина может связать до 20 NFT узлов.',
        text7: 'В течение первых 600 дней одна машина может связать до 10 NFT узлов.',
        text8: 'С 600 по 900 дней каждая машина может связать до 5 NFT узлов.',
        text9: 'С 900 по 1200 дней каждая машина может связать до 2 NFT узлов.',
        text10: 'С 1200 по 1800 дней каждая машина может связать до 1 NFT узла.',
        text11: 'После 1800 дней каждый NFT узел может связать 2 машины.',
        text12: 'После 2400 дней каждый NFT узел может связать 4 машины.',
        text13: 'Расчет вознаграждений:',
        text14: 'R: Общее количество токенов вознаграждения в день (фиксированное значение)',
        text15: 'Количество заложенных токенов i-й машины',
        text16: 'Значение вычислительной мощности i-й машины',
        text17: 'Эффективное время майнинга i-й машины',
  
        text18: 'Где N - общее количество машин, участвующих в майнинге',
        text19: 'Награда за 100 000 монет в 1.23026 раза больше, чем за 10 000 монет, что составляет приблизительно увеличение на 23.03%',
        text20: 'Награда за 1 миллион монет в 1.46052 раза больше, чем за 10 000 монет, что составляет приблизительно увеличение на 46.05%',
        text21: 'Награда за вложение 10 миллионов монет в 1.69078 раза больше, чем за 10 000 монет, что составляет приблизительно увеличение на 69.08%',
        text22: 'Награда за вложение 100 миллионов монет в 1.92103 раза больше, чем за 10 000 монет, что составляет приблизительно увеличение на 92.10%',
        
        title1: 'Получение вознаграждения:',
        text1_1: 'Вознаграждения можно получать один раз каждые 24 часа',
        text1_2: 'Если изначально не было вложено DLC или количество вложенных меньше 10 000, то доход будет накапливаться как вложенные DLC до достижения 10 000 DLC',
        text1_3: '1) Арендная плата будет накапливаться как вложенный капитал до достижения 10 000 DLC',
        text1_4: '2) Минимальный срок аренды составляет 2 часа, максимальный срок аренды не ограничен; когда остается менее часа использования, машина не может быть сдана в аренду и не учитывается в онлайн-вознаграждениях. Майнеры могут увеличивать время в любое время, но каждое увеличение должно составлять не менее 2 часов',
        text1_5: 'Награды можно вывести в любое время. При каждом выводе 10% вновь полученных наград можно снять сразу, а 90% блокируются на 180 дней. Заблокированную часть можно снимать по 0,5% за раз, а после 180 дней вся сумма становится доступной для вывода',
        
        title2: 'Ежедневный расчет дохода от DLC для машин: DLC рассчитывается по цене {num}U,',
        text2_1: 'Пропускная способность моей машины CPU:',
        text2_2: 'Количество NFT узлов:',
        text2_3: 'Регион:',
        text2_4: 'Количество вложенных DLC:',
        text2_5: 'Общая пропускная способность:',
        text2_6: '(Расчет на основе среднего значения 10 NFT и среднего вложения 10 000 DLC)',
        text2_7: 'Пожалуйста, выберите',
        title3: 'Доход от вознаграждений на блокчейне: {dlc_num} DLC (эквивалентно {usdt_num} USD при текущей цене DLC: {num} USD)',
        title4: 'Механизм жалоб и проверок (На основе совместимого с EVM DLC блокчейна DBC AI)',
        text4_1: 'Машина постоянно оснащена механизмом обнаружения блокчейна DBC AI, который сообщает информацию в распределенный кластер обнаружения DBC для определения, находится ли машина в сети; если нет, онлайн-вознаграждения уменьшаются. Если во время использования пользователем происходит прерывание, с пользователя списываются DLC. Лимит штрафов:',
        text4_2: '1) Если не было вложено никаких монет, штрафуется только накопленное вознаграждение, максимум 10 000 DLC',
        text4_3: '2) Если были вложены DLC, максимальный штраф составляет 10 000 DLC',
      }
    },
    
  lanchpad: {
    cont1: {
      title1: 'DeepLink Protocol',
      desc: 'Децентрализованный AI облачный игровой протокол',
      title2: 'Создание инфраструктуры с ультранизкой задержкой для облачного гейминга',
      btn1: 'Войти в PinkSale',
      btn2: 'Скачать Whitepaper',
      btn3: 'Скачать Pitch Deck',
      btn4: 'Публичная продажа DeepLink',
      btn5: 'Добро пожаловать в Награды за узел',
      btn6: 'Скачать клиент',
      "btn7": "Ставьте DLC и получайте 80% прибыли",
      "btn8": "Обмен DLC токенов основной сети DBC EVM",
      "btn9": "Торговать DLC",
      desc1: 'Поддержка DeepLink для получения токенов DLC',
      desc2: '100% Безопасно и проверено',
      desc3: 'Программа NVIDIA Inception',
      "label1": "Адрес получения DLC в сети DBC EVM:",
      "label2": "Сумма перевода:",
      "label3": "Пожалуйста, не используйте кошельки CEX, такие как Binance Wallet, OKX Wallet или Coinbase Wallet, иначе токены могут быть утеряны. Рекомендуется использовать Metamask.",
      "label3_btn": "Подтвердить отправку",
      "tis_msg1": "Кошелёк не подключён",
      "tis_msg2": "Указанный кошелёк не может быть пустым",
      "tis_msg3": "Сумма перевода должна быть больше 0",
      "tis_msg4": "Сначала подключите кошелёк",
      "tis_msg5": "Перевод успешен! Токены DLC основной сети EVM будут отправлены в ваш указанный кошелёк в течение 5 минут.",
      "tis_msg6": "Не удалось получить данные о переводе. Пожалуйста, свяжитесь с администратором: service@deeplink.cloud",
      "tis_msg7": "Недействительный адрес кошелька основной сети. Пожалуйста, проверьте и введите заново."
    },
    price_cont: {
      title: '$DLC уже доступен',
      text: 'Адрес смарт-контракта DLC:'
    },
    cont2: {
      desc1: 'BATTLEFIELD',
      desc2: 'Отображаемая эффективность – это пример двух компьютеров на расстоянии 50 км. Один из них – обычный ноутбук, а другой – мощная машина с GPU. На экране показывается динамическое изображение игры, которое длится примерно 3-5 секунд.',
      btn: 'Посмотреть видео, чтобы узнать больше'
    },
    cont3: {
      text1: 'Сверхнизкая задержка',
      text2: 'Высокое разрешение',
      text3: 'Сверхнизкая задержка',
      text4: 'Без зависаний и разрывов, высокая стабильность',
      desc1: 'DeepLink Protocol реализует революционные технологические прорывы, обеспечивая непревзойденный опыт.',
      desc2: 'Члены основной технической команды с 2011 года занимаются облачными играми и имеют опыт работы в таких компаниях, как Cisco, Intel, Nvidia, Huawei, ShunNet и других. На основе более чем 10 лет разработки появился DeepLink.'
    },
    cont16: {
      title1_1: 'DeepLink Protocol предоставляет технологии с ультранизкой задержкой для облачных игр',
      title1_2: 'Предоставление технологий с ультранизкой задержкой для облачных игр',
      title2: 'На данный момент существуют следующие продукты, разработанные на основе DeepLink Protocol, а также более 20 приложений находятся в разработке',
      text1: 'Удалённое управление',
      text2: 'Облачные игры & Облачные игровые платформы',
      text3: 'Облачные интернет-кафе',
      text4: 'Облачный XR',
      text5: 'XXX Облачные VR игры',
      text6: 'YYY Облачная игровая платформа'
    },
    cont4: {
      title1_1: 'DeepLink Protocol',
      title1_2: 'Использование удалённого управления как точки входа для трафика',
      title2: 'Программное обеспечение DeepLink использует удалённое управление как точку входа для трафика, а облачные игровые платформы и облачные интернет-кафе — как способы монетизации',
      text1_1: '900,000+',
      text1_2: 'Ежемесячные активные пользователи',
      text2_1: '110+',
      text2_2: 'Страны, в которых предоставляются услуги',
      text3_1: '2,500,000+',
      text3_2: 'Общее количество пользователей',
      text4_1: '300,000+',
      text4_2: 'Ежедневные активные пользователи',
      desc1: 'Удалённое управление',
      desc2: 'Облачные игры и Cloud XR',
      desc3: 'Облачные интернет-кафе',
      btn1: 'YouTube KOL уровень 1',
      btn2: 'YouTube KOL уровень 2',
      btn3: 'YouTube KOL уровень 3',
      btn4: 'YouTube KOL уровень 4'
    },
    cont17: {
      title1: 'Бизнес-модель DeepLink Protocol',
      text1: 'Модель комиссий',
      text2: 'Лицензионная модель',
      desc1: 'С каждой транзакции в облачных играх DeepLink Protocol берет комиссию в размере 20-30%, комиссия выплачивается в DLC и 100% DLC немедленно уничтожается.',
      desc2: 'Для приложений, таких как облачные интернет-кафе и облачные киберспортивные отели, каждое приложение за каждое компьютерное устройство платит лицензионный сбор в размере 6 долларов США в месяц.'
    },
    cont5: {
      title1: 'Использование блокчейн-технологий для предоставления майнерами неиспользуемого сетевого трафика',
      desc1: 'Подключение узлов',
      desc2_1: 'Необходимое оборудование',
      desc2_2: 'Перенаправление трафика',
      desc3: 'Майнеры по всему миру могут предоставлять трафиковые узлы через покупку NFT и получать награды в виде DLC токенов',
      desc3_2: '',
      title2: 'Поставщик трафиковых узлов',
      text2: 'Майнеры предоставляют трафиковые узлы', 
      title3: 'Пользовательская сторона',
      text3: 'Индивидуальные пользователи'
    },
    cont6: {
      title1: 'Значительное улучшение конкурентоспособности DeepLink на рынке',
      title2: 'С помощью блокчейн-технологий',
      desc1: 'С помощью смарт-контрактов обеспечивается стекинг криптовалюты, что гарантирует стабильность предоставляемых услуг от GPU-поставщиков',
      desc2: 'Каждый человек может внести свой вклад в сеть и получить вознаграждение, например, предоставляя GPU-серверы или трафиковые узлы',
      desc3: 'Привлечение майнеров для предоставления трафиковых узлов позволяет создать широкое распределение узлов, без необходимости в централизованной компании, которая будет инвестировать в развертывание узлов, что делает систему более децентрализованной и без серверных затрат для компании',
      desc4: 'С помощью смарт-контрактов и стекинга криптовалюты, инвесторы могут выйти из проекта и получить прибыль уже через 1 год, без необходимости ждать 5-10 лет',
      desc5: 'Пользователи могут участвовать в дивидендах от роста проекта, что способствует распространению',
      desc6: 'Пользователи могут реализовать безграничные и свободные платежи',
      text1: 'Стекинг',
      text2: 'Все могут участвовать',
      text3: 'Ноль затрат',
      text4: 'Сокращение времени возврата',
      text5: 'Без границ',
      text6: 'Пользователи получают дивиденды'
    },
    cont7: {
      title1: 'Мы проводим атаку снижения размерности технологии Web3 на технологию Web2',
      desc1: 'Для достижения хорошего опыта облачного гейминга с низкой задержкой, помимо технологий, необходимо, чтобы машины GPU находились в пределах 50 км от пользователя, и для глобального покрытия требуется более 50 000 центров обработки данных.',
      title2: 'Традиционная централизованная платформа облачных игр',
      title3: 'Платформа облачных игр DeepLink на базе блокчейн технологии',
      text1: 'Платформа самостоятельно приобретает машины, что влечёт за собой огромные расходы и неопределённость возврата инвестиций.',
      text2: 'Покрытие машин ограничено, что делает невозможным глобальное покрытие.',
      text3: 'Централизованная технология не может поддерживать связь десятков тысяч данных центров.',
      text4: 'Майнеры и владельцы интернет-кафе предоставляют серверы GPU, получая вознаграждения в токенах, что ускоряет окупаемость инвестиций майнеров и освобождает платформу от необходимости покупки машин.',
      text5: 'Много майнеров и интернет-кафе, что позволяет обеспечить глобальное покрытие.',
      text6: 'Децентрализованная технология блокчейн поддерживает более 100 000 центров обработки данных и более 10 миллионов серверов GPU.'
    },
    cont8: {
      title1: 'Сценарии использования токена DeepLink',
      text1: 'Покупка DLC',
      text2: 'Оплата DLC', 
      text3: 'Оплата USDT',
      text4: 'Оплата DLC',
      text5: 'Внесение DLC и USDT в DEX', 
      text6: 'Продажа DLC',
      text7: 'Пользователь 1',
      text8: 'Программное обеспечение DeepLink', 
      text9: 'Из них 40%DLC будет уничтожено прямо на блокчейне',
      text10: '1. Покупка NFT',
      text11: '2. Покупка времени в облачной игре', 
      text12: '3. Аренда времени на машине в интернет-кафе',
      text13: '4. Покупка времени в личной игре на общих условиях', 
      text14: 'Из них 40%USDT будут использованы для выкупа DLC на DEX, а затем уничтожены', 
      text15: 'Покупка NFT',
      text16: 'DEX и CEX',
      text17: 'Другие торговые платформы',
      text18: 'Майнер',
      text19: 'Награды за майнинг ликвидности',
      text20: 'Награды в течение 2 лет, каждый год по 5 миллиардов DLC'
    },
    cont8_new: {
      text7: 'Индивидуальные пользователи',
      text12: '3. Аренда времени на машинах интернет-кафе',
      text21: 'Коммерческие пользователи',
      text22: 'Протокол DeepLink',
      text23: '1. Покупка лицензии',
      text24: '2. Комиссия 20-30%',
      text25: '100% DLC будет непосредственно уничтожено на блокчейне'
    },
    cont8_new1: {
      title: 'Топовые YouTubers поддерживают наш проект',
      text1: '',
      text2: 'Бизнес пользователи',
      text3: 'Протокол DeepLink',
      text4: '1. Покупка лицензии',
    },
    cont9: {
      title1: 'Награды за майнинг ликвидности токена DeepLink',
      text1: 'Пользователь 1',
      text2: 'Внесение DLC и USDT в DEX', 
      text3: 'Награды за майнинг ликвидности',
      text4: 'Награды на 2 года, каждый год 5 миллиардов DLC',
      text5: 'Внесение ликвидности DLC и USDT в Uniswap и Pancake Swap', 
      text6: 'Ежедневное начисление наград, автоматическое получение через NFT ликвидности',
      text7: '80% наград предназначено для поставщиков ликвидности, 20% для трейдеров',
      text8: 'Ежедневно награждается 13,698,630 DLC'
    },
    cont9_new: {
      title1: 'Награды за майнинг ликвидности токена DeepLink',
      text1: '5% DLC, а также эквивалентные значения USDT, ETH и BNB.',
      text2: 'Внесение в DEX',
      text3: 'Усиление ликвидности DEX',
      text4: '',
      text5: 'Эти 5% токенов будут проданы во время IDO (первоначального предложения DEX). В зависимости от фактически проданных пропорций, в конечном итоге все будет внесено в DEX.',
      text6: 'Внесение ликвидности DLC и USDT в такие DEX, как Uniswap, SushiSwap, Pancake Swap',
      text7: 'Эта ликвидность будет заблокирована на 5 лет, после чего будет использоваться для долгосрочного развития проекта.',
      text8: ''
    },
    cont10: {
      title: 'План и дорожная карта',
      desc1: 'Октябрь 2020 года',
      text1: 'Запуск проекта продукта DeepLink',
      desc2: 'Март 2021 года',
      text2: 'Получение сид-инвестиций',
      desc3: 'Август 2021 года',
      text3: 'Выпуск первой внутренней разработанной версии DeepLink, техническая задержка достигла 3 мс, поддержка игровых клавиатур и мышей',
      desc4: 'Май 2022 года',
      text4: 'Выпуск первой тестовой версии функции удаленного управления DeepLink, поддержка 3К 144Гц дисплеев',
      desc5: 'Август 2022 года',
      text5: 'Выпуск второй тестовой версии функции удаленного управления DeepLink, поддержка виртуальных дисплеев',
      desc6: 'Октябрь 2022 года',
      text6: 'Запуск официальной версии DeepLink, поддержка корейского, китайского и английского языков, основание компании DeepCloudX в Сингапуре для управления DeepLink',
      desc7: 'Декабрь 2022 года',
      text7: 'Получение инвестиций ангельского раунда',
      desc8: 'Апрель 2023 года',
      text8: 'Сотрудничество с крупнейшей сетью интернет-кафе в Корее, создание реальной образцовой комнаты в кафе, тестирование облачных интернет-кафе, положительные отзывы пользователей',
      desc9: 'Июнь 2023 года',
      text9: 'Количество загрузок DeepLink превысило 200 000, пользователи в более чем 100 странах, поддержка дисплеев 8К 60Гц',
      desc10: 'Январь 2021 года',
      text10: 'Определение направления разработки DeepLink и начало дизайна и разработки продукта',
      desc11: 'Апрель 2021 года',
      text11: 'Формирование основной команды завершено, ключевые члены команды начали работать в индустрии облачных игр с 2011 года, участники из таких компаний как Cisco, IntelNvidia, Huawei, Shunwang',
      desc12: 'Март 2022 года',
      text12: 'DeepLink начинает тестирование в сфере интернет-кафе, техническая задержка снижена до 1 мс',
      desc13: 'Июль 2022 года',
      text13: 'Выпуск третьей тестовой версии функции удаленного управления DeepLink, поддержка приватных экранов, удаленное копирование файлов',
      desc14: 'Сентябрь 2022 года',
      text14: 'Выпуск четвертой тестовой версии функции удаленного управления DeepLink, поддержка мультистриминга, поддержка истинных цветов 444',
      desc15: 'Ноябрь 2022 года',
      text15: 'В Сеуле установлено 10 мини-ПК для имитации среды интернет-кафе и тестирования облачных интернет-кафе',
      desc16: 'Февраль 2023 года',
      text16: 'Количество загрузок DeepLink превысило 100 000, пользователи в более чем 50 странах, поддержка дисплеев 4К 200Гц',
      desc17: 'Май 2023 года',
      text17: 'Количество загрузок DeepLink превысило 150 000, пользователи в более чем 80 странах, поддержка кошелька DLC, поддержка вьетнамского, японского'
    },
    cont11: {
      title: 'План развития',
      desc1: 'Июль 2023 года',
      text1: 'Поддержка добавления списка устройств и функции общего вознаграждения',
      desc2: 'Август 2023 года',
      text2: 'Запуск первого облачного интернет-кафе в Сеуле',
      desc3: 'Октябрь 2023 года',
      text3: 'Поддержка функции общего вознаграждения',
      desc4: 'Декабрь 2023 года',
      text4: 'Поддержка функций NFT',
      desc5: 'Q1 2024',
      text5: 'Поддержка функции общего использования игр в веб-версии и создание партнерских отношений, цель - более 500 000 загрузок DeepLink',
      desc6: 'Q2 2024',
      text6: 'Цель - более 1 000 000 загрузок DeepLink и сотрудничество с 10 облачными интернет-кафе',
      desc7: 'Q3 2024',
      text7: 'Цель — достичь более 2 миллионов загрузок для DeepLink и заключить партнерские соглашения с 30 облачными интернет-кафе',
      desc8: 'Q4 2024',
      text8: 'Цель — достичь более 2,5 миллионов загрузок для DeepLink и заключить партнерские соглашения с 40 облачными интернет-кафе',
      desc9: 'Q1 2025',
      text9_1: 'Расширение функционала интернет-кафе:',
      text9_2: 'Поддержка аренды неиспользуемых компьютеров интернет-кафе для совместного использования ресурсов',
      text9_3: 'Поддержка облачного гейминга:',
      text9_4: 'Начальная реализация облачного гейминга для обеспечения эффективного и удобного игрового опыта',
      desc10: 'Q2 2025',
      text10_1: 'Разработка базовых функций:',
      text10_2: 'Виртуальные игровые персонажи на базе ИИ',
      text10_3: 'Разработка виртуального персонажа, который можно активировать в любое время',
      text10_4: 'Оптимизация облачного гейминга: улучшение производительности, снижение задержек, повышение эффективности использования ресурсов',
      text10_5: 'Обеспечение способности ИИ-агента в реальном времени распознавать интерфейс пользователя и предоставлять персонализированные игровые советы',
      text10_6: 'Функция голосового управления: внедрение голосовых команд для управления ИИ-агентом, улучшение эффективности взаимодействия',
      text10_7: 'Оптимизация облачного гейминга: повышение производительности, снижение задержек, увеличение эффективности использования ресурсов',
      desc11: 'Q3 2025',
      text11_1: 'Игровые операции AI-агента: Обеспечение возможности для AI-агента активно помогать пользователям в базовых игровых операциях, таких как руководство по заданиям и сбор ресурсов',
      text11_2: 'Основные достижения:',
      text11_3: 'Превышено 5 миллионов пользователей',
      text11_4: 'Общее количество совместно используемых GPU превысило 3000',
      text11_5: 'Заключено партнерство с более чем 150 облачными интернет-кафе',
      desc12: 'Q4 2025',
      text12_1: 'Средние игровые операции AI-агента: Разработка способности виртуального персонажа выполнять игровые задачи средней сложности, такие как оптимизация стратегии и помощь в бою',
      text12_2: 'Основные достижения:',
      text12_3: 'Превышено 6 миллионов пользователей',
      text12_4: 'Общее количество совместно используемых GPU превысило 4000',
      text12_5: 'Заключено партнерство с более чем 200 облачными интернет-кафе',
      desc13: '2026',
      text13_1: 'Продвинутые игровые операции AI-агента:',
      text13_2: 'Позволить виртуальному персонажу проактивно выполнять сложные игровые операции, включая глобальное стратегическое планирование и выполнение высоко-сложных задач, еще больше улучшая игровой опыт пользователя',
      text13_3: 'Цель — достичь более 15 миллионов загрузок для DeepLink, 50 000 общих компьютеров и заключить партнерские соглашения с 800 облачными интернет-кафе',
    },
    cont12: {
      text: '100 миллиардов будет выпущено в сети DBC EVM',
      text1: 'Инвестиции',
      text1_1: 'Посевной раунд',
      desc1_1: '2 месяца блокировки, 20 месяцев линейного вестинга',
      text1_2: 'Ангельский раунд',
      desc1_2: '1 месяц блокировки, 10 месяцев линейного вестинга',
      text1_3: 'Раунд A',
      desc1_3: '1 месяц блокировки, 10 месяцев линейного вестинга',
      text1_4: 'Публичная продажа',
      desc1_4: '100% разблокировка до TGE',
      text2: 'Команда',
      desc2: '2 месяца блокировки, 20 месяцев линейного вестинга',
      text3: 'Эйрдроп',
      desc3: '20% разблокируются до TGE, 8 месяцев линейного вестинга',
      text4: 'Продажа NFT',
      desc4: '20% разблокируются до TGE, 8 месяцев линейного вестинга',
      text5: 'Майнинг',
      text5_1: 'GPU',
      desc5_1: '16% предназначены для майнеров, предоставляющих мощность GPU, уменьшаясь каждые 4 года. 25% вознаграждений разблокируются сразу, остальное - линейно за 150 дней.',
      text5_2: 'Пропускная способность',
      desc5_2: '4% для майнинга пропускной способности, уменьшаясь каждые 4 года. 25% разблокируются сразу, остальное - линейно за 150 дней.',
      text5_3: 'Соревнования',
      desc5_3: 'Блокировки нет, 10% разблокируются сразу, остальное - линейно за 180 дней.',
      text6: 'Фонд',
      desc6: '6 месяцев блокировки, 8 кварталов линейного вестинга',
      text7: 'Юридический советник',
      desc7: '1 месяц блокировки, 10 месяцев линейного вестинга',
      text8: 'Экосистема',
      desc8: '20% разблокируются до TGE, 8 месяцев линейного вестинга',
      text9: 'Стейкинг',
      text9_1: 'Долгосрочный',
      desc9_1: 'Блокировки нет, 180 дней линейного вестинга',
      text9_2: 'Краткосрочный',
      desc9_2: 'Блокировки нет, 90 дней линейного вестинга',
      text10: 'Итого',
      text11: 'Количество (миллиарды):',
      text12: 'Описание:',
      text13: 'В обороте (миллиарды):',
      text14: 'Не в обороте (миллиарды):',
      text15: '100 миллиардов',
      text16: 'Ликвидность',
      desc16: "0 месяцев обрыва, 0 месяцев линейного вестинга",
    },
    cont13: {
      title: 'Команда и советники',
      intro1: 'Генеральный директор DeepLink',
      intro2: 'Сооснователь DeepLink',
      intro3: 'Главный операционный директор DeepLink (COO)',
      intro4: 'Главный коммерческий директор DeepLink (CBO) и лидер индийского рынка',
      intro5: 'Советник',
      intro6: 'Советник',
      intro7: 'Советник',
      intro8: 'Сооснователь и главный маркетинговый директор',
      user1_1: 'Серийный предприниматель с 14-летним опытом в области искусственного интеллекта и 8-летним опытом разработки технологий блокчейна и распределенных вычислительных сетей.',
      user1_2: 'Окончил Китайский океанский университет по специальности компьютерные науки в 2008 году.',
      user1_3: 'В 2012 году он основал JuShang Intelligent и разработал первого в мире китайского AI голосового помощника "Умный 360".',
      user1_4: 'За три года программное обеспечение было скачано более 17 миллионов раз. В 2014 году он выпустил первую в мире AI колонку "XiaoZhi колонка".',
      user1_5: 'В мае 2017 года он основал DeepBrainChain, обслуживая сотни клиентов в областях ИИ, облачных игр и облачных интернет-кафе.',
      user2_1: 'Серийный предприниматель, опытный специалист в области облачных игр. Был директором института облачных игр Shunwang, ответственным за разработку технологий.',
      user2_2: 'Окончил Чжэцзянский университет по специальности компьютерные науки в 1999 году. С 2001 по 2004 год работал в Чжэцзяне на LanDe, занимаясь телекоммуникациями.',
      user2_3: 'В 2009 году он основал первый в Китае двухъядерный браузер "Sunflower Browser".',
      user2_4: 'С 2010 по 2021 год он присоединился к публичной компании Shunwang и основал Shunwang Cloud Computer, занимая должность директора института Shunwang. Он предоставлял решения для облачных интернет-кафе и управлял облачными играми, сделав свою компанию первой в Китае, предоставляющей решения для облачных игр.',

      user3_1: 'Образовательный фон: 2007 год, факультет китайского языка и литературы Пекинского университета',
      user3_2: '2023 год: Главный операционный директор в DeepLink',
      user3_3: '2022-2023 годы: Главный исполнительный директор DeepBrain Chain',
      user3_4: '2019-2021 годы: Руководитель зарубежного рынка в CELLOGIN',
      user3_5: '2018 год: Главный исполнительный директор FLOW FACTORY',
      
      user4_1: 'Образование и сертификаты: - Получил степень бакалавра делового администрирования в Университете Майсур в 2020 году.',
      user4_2: '- Сертификат по цифровому маркетингу.',
      user4_3: '- Сертификат по поисковой оптимизации (SEO).',
      user4_4: '- Сертификат по блокчейн.',
      user4_5: 'Продажи и маркетинг в Technic Constructions: - Занимал позицию в области продаж и маркетинга, достиг продаж на сумму 500 000 долларов за три месяца.',
      user4_6: "Помощник по развитию бизнеса в BYJU'S: - Работал с 2020 по 2021 год.",
      user4_7: '- Играл ключевую роль в стартапе-единороге стоимостью 10 миллиардов долларов, способствуя росту, созданию партнерств и оптимизации операций в образовательных технологиях.',
      user4_8: 'Сооснователь Space Inn: - Соосновал независимый проект, сосредоточенный на развитии предпринимательских навыков, стратегического мышления и деловой проницательности.',
      user4_9: 'Инвестиции/торговля на фондовом рынке: - Накопил пять лет опыта инвестирования и торговли на фондовом рынке, демонстрируя знания в навигации и использовании инвестиционных возможностей на финансовых рынках.',
      user4_10: 'Стажировки в корпоративных компаниях: - Прошел несколько стажировок в различных корпоративных компаниях, получив ценные знания о корпоративной культуре, эффективных процессах и действенных бизнес-стратегиях.',
      user4_11: 'Опыт в криптовалютной индустрии: - Участвовал в нескольких проектах по криптовалютам и NFT, получив ценный опыт в этой отрасли.',
      user4_12: '- Внес вклад в значимый проект Real-Fi Cardano Empowa, способствуя его развитию и росту. В 2022 году внес вклад в проект Cardano Empowa, стимулируя его рост.',
      user4_13: '- В настоящее время сотрудничает с DeepLink Cloud, расширяя свои знания в области криптовалют.',

      user5_1: 'С 2012 года занимает должность вице-президента в компании Hyunjin ICT, которая предоставляет услуги в области интернета вещей и связи на протяжении 11 лет.',
      user5_2: 'Управление продажами и рынком, опыт работы с крупными проектами в государственных учреждениях.',
      user5_3: '14 лет работы (1998-2012) в крупнейшей IT-компании Южной Кореи, Samsung SDS, где занимался консультациями по информационной стратегии, инновационными бизнес-проектами и разработкой программного обеспечения.',
      user5_4: 'Работал 3 года (2017-2019) в криптовалютной бирже Coinzest, имея опыт работы в крупнейшей компании Кореи и входящей в десятку крупнейших в мире.',
      user5_5: 'Основатель Hyunjin Venus, компании, которая уже 5 лет управляет центром благосостояния для крупных предприятий и государственных учреждений Южной Кореи.',
      user5_6: 'Имеет квалификацию PMP (Профессиональный менеджер по проектам) и CISA (Сертифицированный аудитор информационных систем).',
      
      user6_1: 'Образование: 2003 год, бизнес-школа Гуанхуа, Пекинский университет',
      user6_2: '2023 год: Главный исполнительный директор MetABC',
      user6_3: '2020 год: Главный исполнительный директор HillstoneHub',
      user6_4: '2019 год: Главный исполнительный директор Hillstone Business Center',
      user6_5: '2019 год: Главный операционный директор Hillstone Global (блокчейн)',
      user6_6: '2017 год: Основатель Foundersbridge',
      user6_7: '2017 год: Партнер в Hillstone Partners (приватный капитал)',
      user6_8: '2014 год: Сооснователь BrightA Consulting',
      user6_9: '2012 год: Высший консультант (специалист по бизнесу в Китае)',

      user7_1: "Джозеф Александер — создатель ChatDBC.com, крупномасштабной языковой модели, настроенной Deep Brain Chain.",
      user7_2: "Его опыт — развитие бизнеса DeepLink и DBC в Северной Америке. ",
      user7_3: "Вы можете подписаться на {'@'}ChatDBC в Твиттере, чтобы получать последние новости обо всех DeepLink и DBC.",
     
      user8_1: "Марина Барышева — операционный директор LKI Consulting, удостоенного наград маркетингового агентства Web3, специализирующегося на глубоких технологиях, DeFi, инфраструктурных и игровых проектах.",
      user8_2: "Она успешно масштабировала блокчейн-проекты в более чем 23 странах, управляла маркетинговыми бюджетами от 100 000 до 10 миллионов долларов и продвигала технологический B2B-стартап стоимостью 100 миллионов долларов",
      user8_3: "Опыт Марины заключается в развитии сообщества, узнаваемости бренда и глобальной стратегии GTM.",
      user8_4: "Она является международным оратором, часто выступает на конференциях по криптовалютам по всему миру и является наставником инкубатора проектов искусственного интеллекта, технологий и блокчейна в Хельсинкском университете.",
     
      user9_1: "Брандо — ветеран криптопространства. В 2016 году он начал работать над криптовалютами, что привело к знаменитому бычьему тренду 2017 года.",
      user9_2: "Он основал Bigger Than Race™️ в 2012 году и в 2017 году переименовал его в то, чем он является сегодня.",
      user9_3: "Его квалификация включает сертификацию Web3/Metaverse через EveryRealm, фонд венчурного капитала и лидера в области инноваций и разработок Metaverse, а также сертификацию Blockchain и Bitcoin через IBM.",
      user9_4: "'Мастер шифрования' БРАНДО МЕРФИ",
      user9_5: "Его главное видение и цель — помочь людям достичь финансовой свободы с помощью технологии блокчейна и иметь возможность инвестировать и владеть инфраструктурой новой экономики четвертой и пятой промышленной революции. Сообщество BTR VC помогает лояльным членам по всему миру владеть Технология будущего",
      user9_6: "В 2024 году он стал соучредителем и директором по маркетингу DeepLink Protocol, технологии облачных игр с искусственным интеллектом, которая уравнивает правила игры для геймеров по всему миру. Лос-Анджелес, Калифорния, — его родной город.",
     
     
     
      user10_1: "Саммер является основателем и ведущим FireHustle, медиа-компании, освещающей криптопроекты, инвестиционные стратегии и многое другое. Саммер имеет опыт работы в области бизнес-образования и обширный опыт в сфере телерадиовещания и коммуникаций.",
      user10_2: "Что касается средств массовой информации, ее портфолио включает канал YouTube с более чем 44 000 подписчиков, специализированное сообщество Discord с более чем 3000 участниками, частную группу участников и многое другое.",
      user10_3: "Что касается инвестиций, FireHustle лично инвестировал в более чем 50 стартапов web3 и выступает в качестве партнера по маркетингу, сбору средств и консультированию для многих криптопроектов, венчурных капиталистов, стартап-платформ, синдикатов, групп KOL и т. д.",
     
      user11_1: "Мэнни — энтузиаст и эксперт по блокчейну с более чем десятилетним опытом работы в отрасли и с 2013 года вносит свой вклад в ведущие компании и инициативы",
      user11_2: "Его профессиональный опыт включает в себя: фундаментальные должности в Grayscale, операционную группу в Coinbase, маркетинговые команды в DYDX и Ledger и, наконец, должности в Binance US.",
      user11_3: "Образование: курсы, связанные с блокчейном и криптовалютами, в авторитетных учреждениях США, особенно в Стэнфордском университете, в том числе «Блокчейн и криптовалюта: комплексное введение», «Продвинутая стратегия блокчейна», «Программа для генеральных директоров» и «Программа для директоров по маркетингу». Он имеет специализацию в области финансов и Бизнес-администрирование.",
    },
    cont14: {
      title: 'Наши инвестиционные учреждения',
      organ1: 'Фонд DeepBrain Chain был основан в 2017 году. В январе 2018 года токен DBC был размещен на бирже Huobi. Основная сеть была запущена в 2021 году. DeepBrain Chain представляет собой высокопроизводительную распределенную вычислительную сеть, ориентированную на GPU. Его видение - создать бесконечно масштабируемую распределенную высокопроизводительную вычислительную сеть на основе блокчейн-технологии, которая станет ключевой инфраструктурой для эпохи AI+метавселенной. В основном предоставляет услуги GPU-вычислений в областях искусственного интеллекта, облачных игр и рендеринга фильмов. В настоящее время обслуживает сотни компаний, связанных с искусственным интеллектом и облачными играми, а также более 30,000 специалистов AI.',
      organ2: 'Gobi Capital - это профессиональная венчурная компания с офисами в Шанхае, Пекине и Юго-Восточной Азии, специализирующаяся на инвестициях в ранние технологические проекты в Китае. Среди стратегических инвесторов Gobi Fund такие компании, как IBM, Sierra Ventures, McGraw-Hill и Steamboat Ventures (венчурное подразделение Disney). Gobi инвестировала в такие известные стартапы, как Tuniu, Camera360 и Zhu Yun.',
      organ3: 'Hycons - это облачная платформа GPU-вычислений, разработанная на основе технологии DBC и управляемая Hyunjin ICT. Hycons выделяется благодаря распределенной высокопроизводительной сети на основе DBC и технологии HD стриминга без задержек на основе DeepLink. Платформа предлагает комплексные услуги, включая управление членством, регистрацию и оплату для различных типов обслуживания, таких как предприятия, школы, исследовательские институты и франчайзи. В настоящее время в Корее работает более 1,000 GPU-карт и 2 центра, планируется расширение до 5 центров. Hycons также тестирует приложения для франчайзингового бизнеса в интернет-кафе. Обладая передовой технологической базой, конкурентоспособными тарифами на облачные услуги и автоматизированными сервисами, Hycons стремится стать ведущей в мире платформой GPU-вычислений.',
      organ4: 'ROCK Capital - профессиональный криптовалютный фонд, основанный в 2018 году и базирующийся в Южной Корее, объединяет экспертов по финансовым инвестициям, блокчейну и децентрализации. Широко сотрудничает с различными отечественными и зарубежными компаниями, глобальными проектами, правительствами, учреждениями и другими экономическими субъектами, успешно выполнив более 100 проектов и исследовав множество направлений будущих финансов. Укрепляя сотрудничество, рост, активизацию и партнерские отношения, ROCK обладает богатым опытом и знаниями в различных рыночных условиях. С непоколебимыми принципами ROCK стремится к созданию высокой доходности, расширению инвестиционных возможностей и максимизации капитальных операций для создания конкретного клиентского опыта.',
      organ5: 'Эта венчурная компания сосредоточена на децентрализованной блокчейн-технологии, направленной на развитие четвертой и пятой революций. Обладая глубокими знаниями в различных отраслях, они могут захватывать возможности как в Web3, так и за его пределами. Они совместно инвестируют с известными брендами, такими как Bloktopia, Animoca Brands и Stripe, и установили прочные связи с сетью Singularity, экосистемой Shiba и различными игровыми студиями. Владельцы пропусков BTR-Army могут воспользоваться их услугами.'
    },
    cont15: {
      title: 'Сообщественные мероприятия',
      
    },
    "cont19": {
        "title": "Инвесторы",
        "text": "Уважаемые инвесторы из сферы технологий и Web3 помогают нам расширять границы и переосмысливать будущее."
    },
    "cont20": {
        "title": "Ключевые партнёры",
        "text": "Наша сеть стратегических альянсов объединяет глубокую экспертизу и смелое мышление, чтобы ускорить наше влияние в технологиях и Web3."
    }
  },
  coverage: {
    title: 'Карта глобального распределения пользователей программного обеспечения DeepLink',
    text: 'Общее количество пользователей DeepLink:'
  },
}