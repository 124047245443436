
export default {
  meau: {
    nav: '홈페이지',
    "nav1": "마이닝 노드 구매",
    "nav2": "오리온 GPU 대회",
    nav3: 'DeepLink 소프트웨어',
    nav3_1: '소프트웨어 다운로드',
    nav3_2: '소프트웨어 라이센스',
    nav4: '대역폭 채굴',
    "nav5": "클라우드 PC방",
    "nav6": "블로그",
    nav7: '문서'
  },
  cont1: {
    text1: '게임에 특화된 원격 제어 프로그램',
    text2: '클라우드 게임 플랫폼',
    btn: '지금 무료로 다운로드',
    version: '버전 {ver}',
    text3_1: '',
    text3_2: '커뮤니티에',
    text3_3: '가입 후 DeepLink Coin 보상을 받으세요'
  },
  cont2: {
    text1: 'DeepLink',
    text2: 'e스포츠급의 저지연 기술로 게임을 즐기세요!',
    text3: '핵심능력 및 기술장점',
    text4: '클라우드 게임 핵심능력 기반,전통 원격 제어를 훨씬 뛰어 넘는 체험 ',
    leaf1: {
      text1: '높은 유동성 및 성능',
      text2: '저렴한 원가 ',
      text3: '세계 최고 수준의 인코딩 및 디코딩 솔루션, 동일한 비트 전송률 성능 소비 및 리소스 소비 최소화',
      text4: 'peer-to-peer:  ipv6지원，최대 95% NAT통과 속도,제로 대역폭 사용 '
    },
    leaf2: {
      text1: '높은 프레임 속도, 짧은 대기 시간',
      text2: '',
      text3: 'LAN 딜레이는 15ms 미만이며 ,4K 240Hz의 성능을 보유, WAN 평가 세계 최초 진행 '
    },
    leaf3: {
      text1: '고화질',
      text2: '',
      text3: '스마트 알고리즘은 동일한 비트 전송률에서 시각적 효과를 개선하고 가장 현실 색상으로  복원합니다.'
    },
    leaf4: {
      text1: '높은 안정성',
      text2: '',
      text3: '대량 데이터가 지원하는 패킷 손실 방지 및 혼잡 방지 전략으로 패킷 손실 및 혼잡을 신속하게 조정할 수 있습니다.'
    },
    leaf5: {
      text1: '높은 보안',
      text2: '',
      text3: '모든 데이터는 AES256을 통해 DTLS1.2 암호화됩니다'
    }
  },
  cont3: {
    text1: '로컬 장치 제어하는 ​것처럼 매끄럽게',
    text2: '딥링크는 세 가지 주요 분야와 수십 가지 최적화 기술을 적용하여 패킷 손실률 5% 및 네트워크 지연 100ms 조건에서도 여전히 높은 프레임 속도를 유지하고 원활하게 실행할 수 있습니다. 다양한 네트워크 환경의 요구사항 충족',
    li1: {
      text1: '약한 네트웍간 대결 기술',
      text2: 'FEC 패킷 손실 방지 전략',
      text3: '대역폭 예측',
      text4: 'SVC 코딩 전략'
    },
    li2: {
      text1: '최적화 기술 전송',
      text2: '포트 지문 예측',
      text3: 'IPV6지원',
      text4: 'UPNP',
      text5: '스마트 선택 '
    },
    li3: {
      text1: '처리기술 표시',
      text2: '프레임 속도 적응 전략',
      text3: '동적 화면 예측',
      text4: 'Jitter처리전략',
      text5: 'AI 저코드 HD 렌더링',
      text6: '동적가상 현실기'
    },
    desc3: '랜 < 5ms · 인터시티 네트워크 < 10ms · 도 간 네트워크 < 30ms'
  },
  cont4: {
    text1: '고화질 그래픽 품질 ',
    text2: '매우 선명한 해상도',
    text3: '10.7억종 컬러 지원',
    text4: '4:4:4 무손실 이미지손실',
    text5: 'DeepLink는 항상 더 엄격한 화질 지표를 채택하여 다른 전통적인 원격 기술의 훨씬 능가하는 이미지 체험을 실현합니다. 원격 이미지 손실은 인간의 눈으로 식별할 수 있는 한계값보다 낮습니다. 화면 표시 효과에 대한 크리에이티브 업계의 정확한 요구 사항을 전적으로 지원합니다.',
    text6: '*PSNR은 피크 신호대 잡음비를 말하며, 20dB 미만일 때 영상 왜곡 정도가 높고, 20-30dB일 때 영상 왜곡 정도가 평균, 30-40dB일 때 영상 왜곡 정도가 낮고 40dB보다 크면 영상 왜곡 정도가 극히 낮아 육안으로 감지할 수 없습니다. '
  },
  cont5: {
    text1: '풍부한 주변 장치 지원',
    text2: 'DeepLink는 크리에이티브 업계를 위한 태블릿, 핸들 등 전문 주변기기의 원격 지원을 제공하고 PS, Zbrush, Unity 등 크리에이티브 소프트웨어의 사용을 최적화하여 원격 상태와 로컬 상태의 크리에이티브 생산 도구 체험의 일관성을 확보합니다.'
  },
  cont6: {
    text1: '다중 보장 · 최소 작동',
    block1: {
      title1: '계정 보안',
      desc1: '다중 계정 보호 메커니즘, 안심하고 사용하세요',
      title2: '연결 보안',
      desc2: 'DTLS1.2 프로토콜 보호, 권한 있어야만 액세스 가능',
      title3: '데이터 보안',
      desc3: '256비트 AES 암호화, 전송의 전체 프로세스 기밀유지 ',
      title4: '설비 보안',
      desc4: '설정에 따라 해당 기기는 해당 사용자만 볼 수 있습니다'
    },
    block2: {
      title1: '다중 보안 보장',
      desc1: '모든 연결 보호',
      title2: '간편 사용',
      desc2: '간편 시작'
    },
    block3: {
      title1: '라이트 다운로드 ',
      desc1: 'DeepLink 고객측 약 10M',
      title2: '간편 스타트',
      desc2: '가이드 참조하여 구성 프로세스를 완료하십시오.',
      title3: '간편 사용',
      desc3: '권한 내 모든 장치를 마우스로 원격제어 가능 '
    }
  },
  cont7: {
    text1: '원격 제어 솔루션 제공에 전념',
    block1: {
      word1: '교육업계',
      word2: '게임업계',
      word3: '크리에이티브디자인업계',
      word4: '정보기술업계',
      word5: '출정영업원',
      word6: '분산된공동작업자',
      word7: 'IT운영 및 원격유지보수 ',
      word8: '개인유저및게이머'
    },
    block2: {
      text1: '여러분야의 원격제어시나리오 지원',
      text2: '여러분야기업의 실제요구사항 기반으로 기업이 효율적방식으로 비즈니스목표 달성할수있도록 지원합니다.',
      text3: '유연하고안전한 맞춤형 암호화전략제공',
      text4: '회사직원은 실제권한에따라 언제어디서나 회사리소스에 액세스할수있습니다.'
    },
    block3: {
      text1: '다양한 유저니즈 충족',
      text2: '내부직원을위해 사용할 것인지 여부',
      text3: '또는 외부고객, 공동작업자 및 개인유저에게도 사용할수있습니다.',
      text4: '당사의 원격제어기능은 주류플랫폼 및 장치에서 서비스제공 가능합니다 ',
      text5: '문제해결하는 또다른 간단하고 영리한방법을 제공합니다'
    }
  },
  cont9: {
    block1: {
      text1: '다중 보안',
      text2: '모든 연결 보호',
    },
    block2: { 
      title1: 'PC방 공유',
      text1_1: '256비트 AES 암호화',
      text1_2: '전체 전송 과정의 기밀성',
      title2: '보안 연결',
      text2_1: 'DTLS1.2 프로토콜 보호',
      text2_2: '권한이 있는 경우에만 액세스x',
      title3: '기기 안전',
      text3_1: '설정에 따라 허가된 사용자만 해당 기기 열람 가능',
      title4: '계정 보안',
      text4_1: '블록체인 기술의 다계층 계정 보호 메커니즘 기반 , 기기 정보는 중앙서버를 거치지 않고  P2P 암호화 전송진행',
    },
    block3: {
      text1: 'PC방 공유',
      text2: 'PC방 점주는 딥링크를 통해 기기를 임대하여 수익을 올릴 수 있으며, 사용자는 딥링크를 통해 집에서 약 50km 떨어진 곳에서 PC방 게임을 즐길 수 있습니다',
    },
  },
  cont10: {
    title: '딥링크는 클라우드 PC방 솔루션을 제공합니다',
    desc: '비용 절감과 무한 확장성을 통해 PC방의 수익성 극대화',
    block1: {
      title1: '기존 PC방의 4가지 주요 문제점',
      text1: '또 전기료 인상 ?',
      text1_1: '전기료 부담',
      text1_2: '정부 정책에 따라 인상되는 전기료',
      text2: '40시리즈 그래픽 카드를 PC방에 얼마나 도입될까?',
      text2_1: 'GPU/CPU 업그레이드 부담',
      text2_2: '신규  GPU출시 시즌마다 찾아오는 고가의 GPU업그레이드 구매 부담',
      text3: 'PC방 요금에 대한 소비자 인식도”요금현실화”의 걸림돌?',
      text3_1: '사용 요금 인식',
      text3_2: '천원 이하에 국한된PC 이용요금 인상을 위한 명분 필요.',
      text4: '외부 환경 요인에 어떻게 대처하고 있나요?',
      text4_1: 'PC가동률 저조',
      text4_2: '전염병,기후 등의 문제로 PC방 이용자 감소. 미가동 PC에 대한 처분 문제.',
    },
    block2: {
      title1: '프랜차이즈 클라우드 PC방',
      desc1: '클라우드 PC방의 강점: 창업비용 및 유지비용 절감, 추가 업그레이드 비용없이  PC방 점주에게 최적의 경제적 이점을 제공합니다.',
      text1_1: '약 61%의 비용 절감',
      text1_2: '초기 투자 비용 및 PC 구매 비용 절감 및 감소',
      text2_1: '초 저지연',
      text2_2: '고성능 컴퓨팅  제공으로 게임 반응 속도 향상',
      text3_1: '유지보수 비용 ‘0원’',
      text3_2: '데스크톱 관련  관리 비용 일체 없음',
      text4_1: '매출액 상승',
      text4_2: 'GPU사양별 이용금액 설정,모든 좌석 프리미엄존 가능.',
      text5_1: '전기료 절약',
      text5_2: '에너지절약. 탄소절감',
      text4: '외부 환경 요인에 어떻게 대처하고 있나요?',
      text6_1: 'PC 업그레이드 비용 ‘0원’',
      text6_2: 'GPU/CPU 업그레이드는 클라우드 센터에서 진행.',
    }
  },
  footer: {
    link: '자세히 알아보기',
    community: '커뮤니티 팔로우',
    tip: '설명：커뮤니티종류 Twitter、telegram、reddit、medium、discord、tiktok、YouTube',
    text: 'DeepLink Protocol은 AI, GPU 및 블록체인 기술을 결합하여 초저지연 게임 렌더링 기술을 제공하는 분산형 AI 클라우드 게이밍 프로토콜입니다. 이 프로토콜은 AAA 게임, 클라우드 인터넷 카페, 클라우드 e스포츠 호텔 등의 산업을 위한 비용 효율적인 클라우드 게이밍 솔루션을 제공합니다',
    meau1: '소개',
    meau2: '제품',
    meau3: '블록체인',
    meau4: '주요 에어드롭 이벤트',
    btn5: 'Windows SDK',
    meau3_text1: 'BSC 체인 DLC 브라우저',
    meau3_text2: 'DBC 체인 DLC 브라우저',
    meau3_text3: 'DBC 메인넷 지갑',
    meau4_text1: 'Bybit 300,000U DLC 에어드롭',
    meau4_text2: 'Okx 360,000U DLC 에어드롭',
    meau4_text3: 'Binance 300,000U DLC 에어드롭',
  },
  reward: {
    title1: 'DeepLink 노드',
    desc1: '보상 받을 수 있는 기회를 획득: DeepLink 토큰, Crown NFT, 커뮤니티 거버넌스의 권한',
    title2: '제네시스 노드 NFT 받기',
    text1: '딥링크의 네트워크 유저가 많을수록 더 많은 컴퓨팅과 트래픽이 필요하며. 제네시스 노드는 딥링크 네트워크의 인프라를 제공하여 탈중앙화를 실현하며  딥링크가 더 빠르게 발전할 수 있도록 한다.',
    text2: '제네시스 노드 NFT를 구매하면 네트워크 기여도에 대한 보상을 받을 수 있다',
    text3: '여기에는 DeepLink 토큰, Crown NFT, 커뮤니티 거버넌스의 권한이 포함될 수 있다.',
    text4_1: '질문있으세요?',
    text4_2: '및',
    text4_3: '에서 만나요.',
    btntext: '바로구매',
    text5: '제네시스 노드 NFT가 1,000개 판매될 때마다 가격은 1,000 USD 증가',
    text6_1: '현재 NFT별 ',
    text6_2: '지원하는 노드수량',
    text7: '제네시스 노드NFT총수량',
    text8: '영원히추가발행없음 ',
    text9: '초기가격',
    text10_1: 'NFT별지원',
    text10_2: '노드최대수량',
    text11: '최고가격',
    text12: '제네시스 노드란?',
    text13: '제네시스 노드는 DeepLink 네트워크 컴퓨팅 및 트래픽 전달의 핵심 작업을 수행한다.DeepLink는 마이닝 모델을 통해 더 많은 사람들이 참여하고 이익을 얻을 수 있다.',
    text14: '제네시스 노드에 총 몇 개의 토큰이 지급되나요?',
    text15: '총 코인 수의 30%, 300억개 딥링크 토큰',
    text16: '제네시스 노드 NFT란?',
    text17: '제네시스 노드 NFT를 가지고 있는 자만 채굴에 참여할 수 있다.',
    text18: '제네시스 노드 NFT판매 수익을 어떻게 분배하나요?',
    text19: '수입의 50%는 분산형 유동성 보상 풀(예: Uniswap, Sushiswap의 DeepLink Token/USDC)에 투자되고, 50%는 DeepLink 운영 회사의 수익입니다 ',
    text20: '구체적인 마이닝 메커니즘은 무엇인지요?',
    text21: '연간 총 50억 보상, 3년 동안 반감, DeepLink Crown NFT 수익의 10% 로 DeepLink 토큰 재구매,, 반감 전마다 총 보상 풀에 추가, 50% 토큰의 총 보상으로 투입, 3년 동안 모든 노드의 보상 진행.  예를 들어, 3년 후 DeepLink Crown NFT 수입의 10%가 120억 개의 DeepLink 토큰을 재구매하면  보상 풀의 나머지 총수는 120억 + 150억, 총 270억 토큰 ,두번쨰 3년부터 연간 총 보상은  270억*50%*1/3=45억이다. ',
    text22: '제네시스 노드 기기의 요구 사항은 무엇인지요?',
    text23: '각 기기는 이미 DeepBrain Chain 네트워크에 온체인 필요한. DeepBrain Chain은 분산형 고성능 컴퓨팅 네트워크이며. DeepBrain Chain의 기기는  안정성을 보장하기 위해 DBC를 스테이킹 했다. DeepBrain Chain에 대한 자세한 이해:',
    text24: '제네시스 노드 NFT 보유의 다른 이점은 무엇인지?',
    details: {
      text1: '1.일정 수의 제네시스 노드 NFT를 보유하면 한 국가의 DeepLink NFT 판매 총대리점 권한을 얻을 수 있다(국가의 인구 비율에 따라 3백만 명당 1개의 NFT 필요).',
      text2: '2.딥링크 Coin에어드랍',
      text3: '3.딥링크 인큐베이팅 게임 프로젝트 토큰 에어드랍',
      text4: '4.온라인 게임 권한에 투표',
      text5: '5.게임 NFT 구매 할인',
      text6: '6.클라우드 게임 저작권 및 기간 구매 할인',
      text7: '7.커뮤니티 거버넌스에 참여할 수 있는 기타 권한'
    },
    question: {
      text1_1: '제네시스 노드란?',
      text1_2: '제네시스 노드는 DeepLink 네트워크 컴퓨팅 및 트래픽 전달의 핵심 작업을 수행한다.DeepLink는 마이닝 모델을 통해 더 많은 사람들이 참여하고 이익을 얻을 수 있다.',
      text2_1: '제네시스 노드에 총 몇 개의 토큰이 지급되나요?',
      text2_2: '총 코인 수의 10%, 100억개 딥링크 토큰',
      text3_1: '제네시스 노드 NFT란?',
      text3_2: '제네시스 노드 NFT를 가지고 있는 자만 채굴에 참여할 수 있다.',
      text4_1: '제네시스 노드 NFT판매 수익을 어떻게 분배하나요?',
      text4_2: '수입의 50%는 분산형 유동성 보상 풀(예: Uniswap, Sushiswap의 DeepLink Token/USDC)에 투자되고, 50%는 DeepLink 운영 회사의 수익입니다 ',
      text5_1: '구체적인 마이닝 메커니즘은 무엇인지요?',
      text5_2: '연간 총 50억 보상, 3년 동안 반감, DeepLink Crown NFT 수익의 10% 로 DeepLink 토큰 재구매,, 반감 전마다 총 보상 풀에 추가, 50% 토큰의 총 보상으로 투입, 3년 동안 모든 노드의 보상 진행.  예를 들어, 3년 후 DeepLink Crown NFT 수입의 10%가 120억 개의 DeepLink 토큰을 재구매하면  보상 풀의 나머지 총수는 120억 + 150억, 총 270억 토큰 ,두번쨰 3년부터 연간 총 보상은  270억*50%*1/3=45억이다. ',
      text6_1: '제네시스 노드 기기의 요구 사항은 무엇인지요?',
      text6_2: '각 기기는 이미 DeepBrain Chain 네트워크에 온체인 필요한. DeepBrain Chain은 분산형 고성능 컴퓨팅 네트워크이며. DeepBrain Chain의 기기는  안정성을 보장하기 위해 DBC를 스테이킹 했다. DeepBrain Chain에 대한 자세한 이해:',
      text7_1: '제네시스 노드 NFT 보유의 다른 이점은 무엇인지?',
      text7_2: `1.일정 수의 제네시스 노드 NFT를 보유하면 한 국가의 DeepLink NFT 판매 총대리점 권한을 얻을 수 있다(국가의 인구 비율에 따라 3백만 명당 1개의 NFT 필요).2.딥링크 Coin에어드랍.3.딥링크 인큐베이팅 게임 프로젝트 토큰 에어드랍.4.온라인 게임 권한에 투표.
      5.게임 NFT 구매 할인.6.클라우드 게임 저작권 및 기간 구매 할인.7.커뮤니티 거버넌스에 참여할 수 있는 기타 권한`,
    }
  },
  nft: {
    cont: {
      title1: '크라운NFT',
      desc1: '해당NFT 보유로 DeepLink 소프트웨어의 모든 기능을 1년 동안 사용할 수 있습니다',
      desc2: 'NFT 구매 후 휴면 상태이며, NFT를 사용해야 할 경우 딥링크 소프트웨어에서 활성화할 수 있다, NFT가 활성화되면 ',
      desc3: '1년 사용기간 카운트다운 시작된다. 딥링크 소프트웨어 NFT 수익금의 10%는 노드 보상에 투입된다 ',
      // desc4: '수익의 40%는 유동성 보상 풀에 투자되고, 50%는 딥링크 운영 회사의 수익입니다. ',
      desc4: 'DLC 토큰의 가치를 높이기 위해 수익금의 40%가 소각됩니다.'
    },
    enabl: {
      title1: 'NFT 권한 부여',
      // desc1: 'DeepLink 소프트웨어의 모든 기능에는 1년 사용 권한 포함',
      desc1: 'DeepLink 소프트웨어의 고급 기능에 액세스할 수 있습니다.',
      desc2: 'DeepLink Coin 에어드랍',
      desc3: '딥링크 인큐베이티드 게임 프로젝트 토큰 에어드랍',
      desc4: '온라인 게임 권한  투표',
      desc5: '게임 NFT 구매 할인',
      desc6: '클라우드 게임 저작권 및 기간 구매 할인',
      desc7: '커뮤니티 거버넌스에 참여할 수 있는 기타 권한'
      
    },
    edition: {
      title1: '전문버전',
      title2: '팀 에디션',
      tip: '크라운NFT',
      btn: '바로구매',
      title3: '전문버전 VS  팀 에디션',
      desc: '구체적 기증 사용 권한 ',
      list: {
        li1_1: '내용',
        li1_2: '기본버전',
        li1_3: '전문버전',
        li1_4: '팀 에디션',
        li2_1: '최고의 화질',
        li3_1: '데이터센터전달 ',
        li3_2: '소량',
        li3_3: '대량',
        li4_1: '대역폭전달',
        li4_2: '최대4M',
        li4_3: '최대 50M',
        li4_4: '무제한',
        li5_1: '포트간 지연',
        li5_2: '최저<40ms',
        li5_3: '최저<20ms',
        li6_1: '동시에 원격 제어를 시작할 수 있는 장치의 수',
        li7_1: '기기 수',
        li8_1: '원격 데스크탑',
        li9_1: '원격 카메라',
        li10_1: '원격SSH',
        li11_1: '프라이버시 스크린',
        li12_1: '가상 디스플레이',
        li13_1: '게이밍 키보드와 마우스',
        li14_1: '멀티 스트림',
        li15_1: '444 트루 컬러',
        li16_1: '화면 녹화 화면 캡처',
        li17_1: '듀얼 스크린 투 듀얼 스크린',
        li18_1: '핸들',
        li19_1: '유저/기기 그룹 관리',
        li20_1: 'SSO 원클릭 로그인',
        li21_1: '통합 관리 권한',
        li22_1: '일괄 배포',
        li23_1: '직원 행위 감사',
        li24_1: '워터마크',
        li25_1: '가격',
        li26_1: '단일 시스템 데스크톱 세션',
        li27_1: '파일 전송',

        new_text1: '동일한 지갑 주소가 여러 기기에서 동시에 로그인되어 있으면, 기기 중 하나만 원격 제어를 시작할 수 있습니다.',
        new_text2: '기계는 원격으로 제어되는 창을 두 개만 열 수 있습니다',
      }
    }
  },
  internetCafe: {
    title1: '딥링크 클라우드 PC방 솔루션',
    block1: {
      text1: 'PC방 점주 하드웨어에 대한 투자비용 60% 이상 절감',
      text2: '투자금 회수 기간 40% 단축',
      text3: '영상 보기',
    },
    title2: '클라우드 PC방 NFT 가격',
    block2: {
      desc: '수익금의 40%는 DLC 투자자를 위해 DEX 거래소의 DLC 코인 풀에 다시 투입됩니다.',
      text1_1: '50대 기기 지원',
      text1_2: '1년에 3600 USDT',
      text2_1: '100대 기기 지원',
      text2_2: '1년에 7200 USDT',
      text3_1: '150대 기기 지원',
      text3_2: '1년에 10800 USDT',
      text4_1: '200대 기기 지원',
      text4_2: '1년에 14400 USDT',
    },
    title3: 'DeepLink는 전 세계 클라우드 PC방 에이전트를  모집합니다',
    block3: {
      text1_1: '권한',
      text1_2: '시장 가격보다 낮은 가격으로 PC방 NFT를 받으세요!',
      text2_1: '책임',
      text2_2: '에이전트 지역에서 클라우드 PC방 파트너를 찾고 있습니다,기존 PC방에 클라우드 PC방 서비스를 제공할 수 있습니다.',
      text3_1: '자격 평가 기준',
      text3_2: '해당 지역의 PC방 사업과 관련된 자원을 보유해야 하며, 지역 인구 수에 상응하는 양의 DLC 토큰을 보유해야 합니다.',
    },
    title4: '클라우드 PC방 Q&A',
    block4: {
      text1_1: '클라우드 PC방이란 무엇인가요?',
      text1_2: '클라우드 PC방이란 기존 PC방에 고사양 GPU가 아닌 모니터와 미니 PC만 구성한 후 네트워크를 통해 원격 GPU 서버에 연결하면 됩니다. 사용자는 PC방에서 다양한 종류의 GPU 사양을 선택할 수 있으며, PC방 점주는 게임 리소스를 관리할 필요가 없고, PC방은 사용자가 사용한 시간만큼의 비용을 지불하면 됩니다.',
      text2_1: '에이전트 되려면 어떻게 신청할 수 있나요?',
      text2_2: '에서 텔레그램에 가입해 문의할 수 있습니다.',
      text3_1: '에이전트에게는 어떤 혜택이 있나요?',
      text3_2: '에이전트는 해당 지역에서의 독점 권리를 가지며, 클라우드 플랫폼을 확장하여 PC방에 서비스를 제공함으로써 각 NFT의 가격 차액을 얻습니다.',
      text4_1: '에이전트는 딥링크와 공식 계약을 체결해야 하나요?',
      text4_2: '네, 공식 계약이 필요합니다.',
      text5_1: '클라우드 PC방 NFT 수익은 DLC 토큰 가격에 어떤 영향을 끼치나요?',
      text5_2: '클라우드 PC방 NFT 수익금 중 40%는 유니스왑(Uniswap)과 같은 탈중앙화 거래소에서 DLC-USDT로 연결되어 DLC 토큰 구매에 사용되고, 구매한 DLC는 소각됩니다.',
      text6_1: '클라우드 PC방 NFT 수익이 DLC 토큰 구매에 투명하게 증빙될 수 있는 방법은?',
      text6_2: '발행된 클라우드 PC방 NFT의 개수는 온체인에서 확인할 수 있으며 발행 개수에 따라 NFT 수익을 계산하는데 사용할 수 있습니다.',
    },
    title5: '클라우드 PC방 파트너'
  },
  gameFi: {
    title1: '클라우드 GameFi 플랫폼',
    block1: {
      text1: '클라우드 GameFi 플랫폼',
      text1_1: '모든 사용자는 딥링크를 통해 게임 설치없이 GameFi 게임을 플레이할 수 있습니다.',
      text1_2: '모든 국가에서 게임 설치에 제한이 없으며, Apple 앱스토어에서 다운로드할 필요가 없습니다.',
      text1_3: 'GameFi 게임 제작사가 더 많은 사용자들에게 도달할 수 있도록 지원',
      text2: '게임 개발사 협력 방법',
      text2_1: '광고 협력',
      text2_2: '트래픽 협력',
      text2_3: '게임 분할',
      text2_4: 'NFT 협력',
    },
    title2: 'GameFi Q&A',
    block2: {
      text1_1: '클라우드 GameFi 플랫폼이란 무엇인가요?',
      text1_2: '딥링크는 양질의 게임 파트너를 선정하여 해당 게임을 딥링크 클라우드 플랫폼에 추가해 사용자가 게임을 설치하지 않고도 딥링크를 통해 바로 GameFi게임을 플레이할 수 있도록 합니다.',
      text2_1: '클라우드 GameFi 플랫폼은 모바일 게임을 지원하나요?',
      text2_2: 'PC에서 모바일 게임을 플레이할 수 있으며, 동시에 여러 게임 창을 열 수 있도록 지원합니다.',
      text3_1: 'GameFi 개발사가 딥링크와 협업하면 어떤 이점이 있나요?',
      text3_2: '딥링크는 파트너 개발사의 브랜드 홍보와 함께 더 많은 게이머를 유치할 수 있도록 지원합니다.',
      text4_1: 'GameFi 개발사는 딥링크와 공식 계약을 체결해야 하나요??',
      text4_2: '필요없습니다, 딥링크 자체적으로 양질의 파트너를 선별하여, 선별 과정에 통과된 게임들은 딥링크 플랫폼에 자동으로 등록됩니다',
      text5_1: '딥링크와 함께 협력하고 싶다면 어떻게 신청하나요?',
      text5_2: '신청 이메일:',
    }
  },

  
  lanchpad: {
    cont1: {
      title1: 'DeepLink Protocol',
      desc: '탈중앙화 AI 클라우드 게임 프로토콜',
      title2: '클라우드 게이밍을 위한 초저지연 렌더링 인프라 구축',
      btn1: 'PinkSale 참여',
      btn2: '백서 다운로드',
      btn3: 'Pitch Deck 다운로드',
      btn4: 'DeepLink Public Sale',
      btn5: '노드 보상에 오신 것을 환영합니다',
      btn6: '클라이언트 다운로드',
      "btn7": "DLC 스테이킹으로 80% 이자 획득",
      "btn8": "DBC EVM 메인넷 DLC 토큰 교환",
      "btn9": "DLC 거래",
      desc1: 'DLC 토큰을 얻기 위해 DeepLink 지원',
      desc2: '100% 안전 & 검증됨',
      desc3: 'NVIDIA Inception Program',
      "label1": "DBC EVM 체인의 DLC 수령 주소:",
      "label2": "송금 금액:",
      "label3": "Binance 지갑, OKX 지갑, Coinbase 지갑과 같은 CEX 지갑을 사용하지 마십시오. 그렇지 않으면 토큰이 손실될 수 있습니다. Metamask 사용을 권장합니다.",
      "label3_btn": "전송 확인",
      "tis_msg1": "지갑이 연결되지 않았습니다.",
      "tis_msg2": "지정된 지갑은 비워둘 수 없습니다.",
      "tis_msg3": "송금 금액은 0보다 커야 합니다.",
      "tis_msg4": "먼저 지갑을 연결해주세요.",
      "tis_msg5": "송금 성공! 메인넷 EVM 지갑의 DLC 토큰이 5분 이내에 지정된 지갑으로 전송됩니다.",
      "tis_msg6": "송금 데이터를 가져오는 데 실패했습니다. 관리자에게 문의하세요: service{'@'}deeplink.cloud",
      "tis_msg7": "잘못된 메인넷 지갑 주소입니다. 확인 후 다시 입력하세요."
    },
    price_cont: {
      title: '$DLC가 출시되었습니다',
      text: 'DLC 계약 주소:'
    },
    cont2: {
      desc1: 'BATTLEFIELD',
      desc2: '표시된 효율은 50km 떨어진 두 대의 컴퓨터를 예로 든 것으로, 제어 컴퓨터는 일반적인 노트북이고, 제어되는 컴퓨터는 고급 GPU 머신입니다. 게임 동적 화면을 보여주며, 동적인 형태로 약 3-5초 정도입니다.',
      btn: '비디오 재생하여 더 알아보기'
    },
    cont3: {
      text1: '초저지연',
      text2: '고해상도',
      text3: '초저지연',
      text4: '끊김 없이 고정밀, 높은 안정성',
      desc1: 'DeepLink Protocol은 혁신적인 기술 돌파구를 이루어 비교할 수 없는 완벽한 경험을 제공합니다.',
      desc2: '핵심 기술 팀원은 2011년부터 클라우드 게임 산업에 참여했으며, Cisco, Intel, Nvidia, Huawei, ShunNet 등의 회사 출신들이 포함되어 있습니다. 10년 이상의 연구 개발을 통해 DeepLink가 탄생했습니다.'
    },
    cont16: {
      title1_1: 'DeepLink Protocol은 클라우드 게임에 초저지연 기술 서비스를 제공합니다',
      title1_2: '클라우드 게임에 초저지연 기술 서비스를 제공합니다',
      title2: '현재 DeepLink Protocol을 기반으로 개발된 제품은 다음과 같으며, 20개 이상의 애플리케이션이 개발 중에 있습니다',
      text1: '원격 제어',
      text2: '클라우드 게임 & 클라우드 게임 플랫폼',
      text3: '클라우드 PC방',
      text4: '클라우드 XR',
      text5: 'XXX 클라우드 VR 게임',
      text6: 'YYY 클라우드 게임 플랫폼'
    },
    cont4: {
      title1_1: 'DeepLink Protocol',
      title1_2: '원격 제어 도구를 트래픽 진입점으로 사용',
      title2: 'DeepLink 소프트웨어는 원격 제어 도구를 트래픽 진입점으로 사용하고, 클라우드 게임 플랫폼과 클라우드 PC방을 수익화 수단으로 사용',
      text1_1: '900,000+',
      text1_2: '월간 활성 사용자 수',
      text2_1: '110+',
      text2_2: '서비스 제공 국가 수',
      text3_1: '2,500,000+',
      text3_2: '전체 사용자 수',
      text4_1: '300,000+',
      text4_2: '일일 활성 사용자 수',
      desc1: '원격 제어 도구',
      desc2: '클라우드 게임 및 클라우드 XR',
      desc3: '클라우드 PC방',
      btn1: 'YouTube KOL 레벨 1',
      btn2: 'YouTube KOL 레벨 2',
      btn3: 'YouTube KOL 레벨 3',
      btn4: 'YouTube KOL 레벨 4'
    },
    cont17: {
      title1: 'DeepLink Protocol의 비즈니스 모델',
      text1: '수수료 모델',
      text2: '라이센스 모델',
      desc1: '모든 클라우드 게임 거래에서 DeepLink Protocol은 20-30%의 수수료를 부과하며, 수수료는 DLC로 결제되며 100% DLC는 실시간으로 소각됩니다.',
      desc2: '클라우드 PC방과 클라우드 e스포츠 호텔 애플리케이션의 경우, 각 애플리케이션은 각 컴퓨터 단말기마다 매월 6달러의 라이센스 비용을 지불합니다.'
    },
    cont5: {
      title1: '블록체인 기술을 통해 채굴자가 유휴 네트워크 트래픽 제공',
      desc1: '노드 접속',
      desc2_1: '필요한 장비',
      desc2_2: '트래픽 전달',
      desc3: '전 세계의 채굴자가 NFT를 구매하여 트래픽 노드를 제공하고 DLC 토큰 보상을 얻습니다.',
      desc3_2: '',
      title2: '트래픽 노드 제공자',
      text2: '채굴자가 제공하는 트래픽 노드', 
      title3: '사용자 측',
      text3: '개인 사용자'
    },
    cont6: {
      title1: 'DeepLink 시장 경쟁력 대폭 향상',
      title2: '블록체인 기술을 통해',
      desc1: '스마트 계약을 통해 디지털 화폐를 스테이킹하여 GPU 제공자가 안정적인 서비스를 제공하도록 보장',
      desc2: '모든 사람이 네트워크에 기여하고 보상을 받을 수 있습니다. 예를 들어 GPU 서버 제공, 트래픽 노드 제공',
      desc3: '채굴자를 트래픽 전달 노드 제공자로 도입하여 노드 수가 광범위하게 분포하고 중앙화된 회사가 기계 배치를 투자할 필요 없이 더 많은 분산화가 가능하며 회사는 서버 비용이 0원',
      desc4: '스마트 계약을 통해 디지털 화폐를 스테이킹하여 투자자는 5-10년을 기다릴 필요 없이 약 1년 이내에 출구하여 보상을 받을 수 있습니다.',
      desc5: '사용자는 프로젝트 성장의 배당에 참여할 수 있어 확산을 촉진합니다.',
      desc6: '사용자는 국경 없는 자유로운 결제를 실현할 수 있습니다.',
      text1: '스테이킹',
      text2: '모두 참여',
      text3: '제로 비용',
      text4: '보상 시간 단축',
      text5: '국경 없음',
      text6: '사용자는 배당을 받습니다'
    },
    cont7: {
      title1: 'Web3 기술로 Web2 패러다임을 재정의하다',
      desc1: '클라우드 게이밍에서 최상의 저지연 경험을 제공하기 위해서는 단순한 기술적 진보를 넘어, GPU 서버가 사용자로부터 50km 이내에 위치해야 합니다. 이를 실현하려면 전 세계적으로 5만 개 이상의 데이터 센터가 구축되어야 하며, 이는 글로벌 사용자에게 끊김 없는 고성능 서비스를 보장하는 핵심 인프라입니다',
      title2: '기존 중앙집중형 클라우드 게이밍 플랫폼',
      title3: 'DeepLink의 블록체인 기반 클라우드 게이밍 플랫폼',
      text1: '플랫폼이 직접 기계를 구매하여 비용이 많이 들고, 수익성 회수가 불투명합니다.',
      text2: '기계의 커버리지가 좁아 전 세계적인 커버리지를 제공할 수 없습니다.',
      text3: '중앙집중식 기술로는 수만 개의 데이터 센터를 지원할 수 없습니다.',
      text4: '채굴자와 인터넷 카페 주인이 GPU 서버를 제공하며, 토큰 보상을 통해 채굴자의 투자 회수 기간이 단축되며, 플랫폼은 기계 구매 부담이 없습니다.',
      text5: '채굴자와 인터넷 카페의 수가 많아 전 세계적으로 커버할 수 있습니다.',
      text6: '분산된 블록체인 기술은 10만 개 이상의 데이터 센터와 1000만 개 이상의 GPU 서버를 지원합니다.'
    },
    cont8: {
      title1: 'DeepLink 토큰 사용 시나리오',
      text1: 'DLC 구매',
      text2: 'DLC 지불', 
      text3: 'USDT 지불',
      text4: 'DLC 지불',
      text5: 'DLC 및 USDT를 DEX에 주입', 
      text6: 'DLC 판매',
      text7: '사용자 1',
      text8: 'DeepLink 소프트웨어', 
      text9: '그 중 40%DLC는 체인 상에서 직접 소멸됩니다',
      text10: '1. NFT 구매',
      text11: '2. 클라우드 게임 시간 구매', 
      text12: '3. 인터넷 카페 기계 시간 대여',
      text13: '4. 개인 공유 게임 시간 구매', 
      text14: '그 중 40%USDT는 DEX에서 DLC를 매수한 후 소멸됩니다', 
      text15: 'NFT 구매',
      text16: 'DEX 및 CEX',
      text17: '기타 거래 플랫폼',
      text18: '채굴자',
      text19: '유동성 채굴 보상',
      text20: '보상 기간 2년, 매년 50억 DLC'
    },
    cont8_new: {
      text7: '개인 사용자',
      text12: '3. 인터넷 카페 기계 임대 시간',
      text21: '상업 사용자',
      text22: 'DeepLink 프로토콜',
      text23: '1. 라이선스 구매',
      text24: '2. 20-30%의 수수료',
      text25: '100%의 DLC가 체인상에서 직접 소멸됩니다'
    },
    cont8_new1: {
      title: '최고의 YouTubers가 우리 프로젝트를 지원합니다',
      text1: '',
      text2: '사업 사용자',
      text3: 'DeepLink 프로토콜',
      text4: '1. 라이선스 구매',
    },
    cont9: {
      title1: 'DeepLink 토큰 유동성 채굴 보상',
      text1: '사용자 1',
      text2: 'DEX에 DLC와 USDT 주입', 
      text3: '유동성 채굴 보상',
      text4: '2년간 보상, 매년 50억 DLC',
      text5: 'Uniswap과 Pancake Swap에 DLC와 USDT 유동성 주입', 
      text6: '매일 한 번 보상 지급, 유동성 NFT를 통해 자동으로 수령',
      text7: '보상의 80%는 유동성 제공자에게, 20%는 거래자에게 주어집니다',
      text8: '매일 13,698,630개의 DLC 보상'
    },
    cont9_new: {
      title1: 'DeepLink 토큰 유동성 채굴 보상',
      text1: '5%의 DLC와 동등한 가치의 USDT, ETH, BNB.',
      text2: 'DEX 주입',
      text3: 'DEX의 유동성 강화',
      text4: '',
      text5: '이 5%의 토큰은 IDO(초기 DEX 발행) 기간 동안 판매될 예정입니다. 실제 판매 비율에 따라 최종적으로 모두 DEX에 주입됩니다.',
      text6: 'DLC와 USDT의 유동성을 Uniswap, SushiSwap, Pancake Swap 등의 DEX에 주입',
      text7: '이 유동성은 5년 동안 잠겨 있으며, 이후 프로젝트의 장기적인 발전을 위해 사용됩니다.',
      text8: ''
    },
    cont10: {
      title: '로드맵 및 계획',
      desc1: '2020년 10월',
      text1: 'DeepLink 제품 기획 시작',
      desc2: '2021년 3월',
      text2: '시드 투자 유치',
      desc3: '2021년 8월',
      text3: '첫 DeepLink 내부 개발 버전 출시, 기술 지연 3ms 달성, 게임 키보드 및 마우스 지원',
      desc4: '2022년 5월',
      text4: '첫 DeepLink 원격 제어 기능 테스트 버전 출시, 3K 144HZ 디스플레이 지원',
      desc5: '2022년 8월',
      text5: '두 번째 DeepLink 원격 제어 기능 테스트 버전 출시, 가상 디스플레이 지원',
      desc6: '2022년 10월',
      text6: 'DeepLink 공식 버전 출시, 한국어, 중국어, 영어 지원 및 싱가포르에 DeepCloudX 회사 설립',
      desc7: '2022년 12월',
      text7: '엔젤 투자 유치',
      desc8: '2023년 4월',
      text8: '한국 최대 체인 인터넷 카페와 협력하여 진짜 환경 샘플룸을 구축, 클라우드 인터넷 카페 테스트, 사용자로부터 호평',
      desc9: '2023년 6월',
      text9: 'DeepLink 다운로드 수 200,000 초과, 100개국 이상의 사용자, 8K 60Hz 디스플레이 지원',
      desc10: '2021년 1월',
      text10: 'DeepLink 연구개발 방향 확정 및 제품 설계 및 개발 시작',
      desc11: '2021년 4월',
      text11: '핵심 팀 완성, 핵심 팀원들은 2011년부터 클라우드 게이밍 산업에 참여, Cisco, IntelNvidia, 화웨이, 순망 등 회사 출신',
      desc12: '2022년 3월',
      text12: 'DeepLink가 인터넷 카페 분야에서 테스트 시작, 기술 지연 1ms 달성',
      desc13: '2022년 7월',
      text13: '세 번째 DeepLink 원격 제어 기능 테스트 버전 출시, 개인 정보 보호 화면, 원격 파일 복사 지원',
      desc14: '2022년 9월',
      text14: '네 번째 DeepLink 원격 제어 기능 테스트 버전 출시, 멀티 스트리밍, 444 진정색 지원',
      desc15: '2022년 11월',
      text15: '서울에서 10대의 미니 PC 기계 설치 및 가상 인터넷 카페 환경 구축하여 클라우드 인터넷 카페 테스트',
      desc16: '2023년 2월',
      text16: 'DeepLink 다운로드 10만 돌파, 50개국 이상의 사용자, 4K 200HZ 디스플레이 지원',
      desc17: '2023년 5월',
      text17: 'DeepLink 다운로드 15만 돌파, 80개국 이상의 사용자, DLC 지갑 기능, 베트남어, 일본어 지원'
    },
    cont11: {
      title: '발전 계획',
      desc1: '2023년 7월',
      text1: '장치 목록 추가 및 공유 보상 기능 지원',
      desc2: '2023년 8월',
      text2: '서울에서 첫 번째 클라우드 인터넷 카페 출시',
      desc3: '2023년 10월',
      text3: '공유 보상 기능 지원',
      desc4: '2023년 12월',
      text4: 'NFT 기능 지원',
      desc5: '2024년 1분기',
      text5: '웹 버전에서 게임 공유 기능 지원 및 파트너십 구축, DeepLink 다운로드 수 50만 회 초과 목표',
      desc6: '2024년 2분기',
      text6: 'DeepLink 다운로드 수 100만 회 초과 및 10개 클라우드 인터넷 카페와 협력 목표',
      desc7: '2024년 3분기',
      text7: 'DeepLink의 다운로드 수 200만 회 이상 달성 및 30개의 클라우드 인터넷 카페와 파트너십 체결 목표',
      desc8: '2024년 4분기',
      text8: 'DeepLink의 다운로드 수 250만 회 이상 달성 및 40개의 클라우드 인터넷 카페와 파트너십 체결 목표',
      desc9: '2025년 1분기',
      text9_1: 'PC방 기능 확장：',
      text9_2: 'PC방 유휴 기기 대여 지원을 통해 자원 공유 실현',
      text9_3: '클라우드 게임 지원：',
      text9_4: '클라우드 게임 기능을 초기 구현하여 효율적이고 편리한 게임 경험 제공',
      desc10: '2025년 Q2',
      text10_1: '기본 기능 개발：',
      text10_2: 'AI 기반 가상 게임 캐릭터',
      text10_3: '언제든 활성화할 수 있는 가상 캐릭터 개발',
      text10_4: '클라우드 게임 최적화: 성능 향상, 지연 감소, 자원 효율성 증대',
      text10_5: 'AI 에이전트가 사용자의 게임 인터페이스를 실시간으로 인식하고 맞춤형 게임 가이드를 제공',
      text10_6: '음성 제어 기능: 음성 명령을 통해 AI 에이전트를 조작하고 게임 조작 효율성 향상',
      text10_7: '클라우드 게임 최적화: 클라우드 게임 성능 향상, 지연 감소 및 자원 활용 효율 증대',
      desc11: '2025년 3분기',
      text11_1: 'AI 에이전트 게임 조작: AI 에이전트가 기본적인 게임 작업을 사용자에게 적극적으로 지원 (작업 안내 및 자원 수집)',
      text11_2: '이정표:',
      text11_3: '사용자 수 500만 돌파',
      text11_4: '공유 GPU 3000개 초과',
      text11_5: '150개 이상의 클라우드 인터넷 카페와 제휴',
      desc12: '2025년 4분기',
      text12_1: 'AI 에이전트 중급 게임 조작: 가상 캐릭터가 전략 최적화 및 전투 지원과 같은 중급 난이도의 게임 작업을 처리할 수 있는 능력 개발',
      text12_2: '이정표:',
      text12_3: '사용자 수 600만 돌파',
      text12_4: '공유 GPU 4000개 초과',
      text12_5: '200개 이상의 클라우드 인터넷 카페와 제휴',
      desc13: '2026',
      text13_1: 'AI 에이전트 고급 게임 작업:',
      text13_2: '가상 캐릭터가 글로벌 전략 계획 및 고난이도 작업을 완료하는 등 고급 게임 작업을 적극적으로 수행할 수 있도록 하여 사용자 게임 경험을 더욱 향상',
      text13_3: 'DeepLink 다운로드 1500만 회 이상 달성, 50,000대 공유 컴퓨터 보유, 800개의 클라우드 인터넷 카페와의 파트너십 목표',
    },
    cont12: {
      text: '1000억이 DBC EVM 체인에서 발행됩니다',
      text1: '투자',
      text1_1: '시드 라운드',
      desc1_1: '2개월 락업, 20개월 선형 베스팅',
      text1_2: '엔젤 라운드',
      desc1_2: '1개월 락업, 10개월 선형 베스팅',
      text1_3: 'A 라운드',
      desc1_3: '1개월 락업, 10개월 선형 베스팅',
      text1_4: '공개 판매',
      desc1_4: 'TGE 이전 100% 해제',
      text2: '팀',
      desc2: '2개월 락업, 20개월 선형 베스팅',
      text3: '에어드롭',
      desc3: 'TGE 전에 20%가 언락되며, 8개월간 선형 베스팅',
      text4: 'NFT 판매',
      desc4: 'TGE 전에 20%가 언락되며, 8개월간 선형 베스팅',
      text5: '마이닝',
      text5_1: 'GPU',
      desc5_1: '16%는 GPU 파워를 제공하는 채굴자들에게 할당되며, 4년마다 반감됩니다. TGE 이후 보상이 시작되며, 25%는 즉시 언락되고 나머지는 150일간 선형 베스팅으로 언락됩니다.',
      text5_2: '대역폭',
      desc5_2: '4%는 대역폭 채굴에 할당되며, 4년마다 반감됩니다. 25%는 즉시 언락되고 나머지는 150일간 선형 베스팅으로 언락됩니다.',
      text5_3: '경쟁',
      desc5_3: '락업 없음, 10%는 즉시 언락되고 나머지는 180일간 선형 베스팅으로 언락됩니다.',
      text6: '재단',
      desc6: '6개월 락업, 8분기 선형 베스팅',
      text7: '법률 고문',
      desc7: '1개월 락업, 10개월 선형 베스팅',
      text8: '생태계',
      desc8: 'TGE 전에 20%가 언락되며, 8개월간 선형 베스팅',
      text9: '스테이킹',
      text9_1: '장기',
      desc9_1: '락업 없음, 180일 선형 베스팅',
      text9_2: '단기',
      desc9_2: '락업 없음, 90일 선형 베스팅',
      text10: '총계',
      text11: '수량 (10억):',
      text12: '설명:',
      text13: '유통 중 (10억):',
      text14: '미유통 (10억):',
      text15: '1000억',
      text16: '유동성',
      desc16: "0개월 클리프 ，0개월 선형 베스팅",
    },
    cont13: {
      title: '팀 및 고문',
      intro1: 'DeepLink CEO',
      intro2: 'DeepLink 공동 창립자',
      intro3: 'DeepLink 최고운영책임자(COO)',
      intro4: 'DeepLink 최고사업책임자(CBO) 겸 인도 시장 리더',
      intro5: '고문',
      intro6: '고문',
      intro7: '고문',
      intro8: '공동 창립자 겸 최고마케팅책임자',
      user1_1: '연속 창업가로서 14년의 인공지능 경험과 8년의 블록체인 및 분산 컴퓨팅 네트워크 기술 개발 경험이 있습니다.',
      user1_2: '2008년 중국해양대학 컴퓨터과학 전공 졸업.',
      user1_3: '2012년, 그는 주상지능을 설립하고 세계 최초의 중국어 AI 음성 보조인 "지능360"을 개발했습니다.',
      user1_4: '3년 안에, 이 소프트웨어의 다운로드 수는 1700만을 넘어섰습니다. 2014년, 그는 세계 최초의 AI 스피커 "소지 스피커"를 출시했습니다.',
      user1_5: '2017년 5월, 그는 DeepBrainChain을 창립하여 수백 개의 AI, 클라우드 게임 및 클라우드 인터넷 카페 고객에게 서비스를 제공하고 있습니다.',
      user2_1: '연속 창업가이자 선임 클라우드 게임 전문가. 전 순망 클라우드 게임 연구소 소장으로 기술 개발을 담당했습니다.',
      user2_2: '1999년 절강대학 컴퓨터 과학 전공 졸업. 2001년부터 2004년까지 절강 랜드에서 통신 관련 업무에 참여했습니다.',
      user2_3: '2009년, 그는 중국 최초의 듀얼 코어 브라우저 "해바라기 브라우저"를 창립했습니다.',
      user2_4: '2010년부터 2021년까지 상장 회사 순망에 합류하여 순망 클라우드 컴퓨터를 설립하고 순망 연구소 소장을 역임하며 클라우드 인터넷 카페 솔루션을 제공하고 클라우드 게임을 운영하여 회사를 중국 최초의 클라우드 게임 솔루션 제공 회사로 만들었습니다.',

      user3_1: '교육 배경: 2007년 베이징 대학 중문과',
      user3_2: '2023년: DeepLink 최고운영책임자',
      user3_3: '2022-2023년: DeepBrain Chain 최고경영자',
      user3_4: '2019-2021년: CELLOGIN 해외시장 리더',
      user3_5: '2018년: FLOW FACTORY 최고경영자',
      
      user4_1: '교육 및 자격증: - 2020년 마이소르 대학에서 경영학 학사 학위 취득.',
      user4_2: '- 디지털 마케팅 자격증.',
      user4_3: '- 검색 엔진 최적화(SEO) 자격증.',
      user4_4: '- 블록체인 자격증.',
      user4_5: 'Technic Constructions에서의 판매 및 마케팅: - 판매 및 마케팅 역할을 맡아 세 달 만에 50만 달러의 매출 달성.',
      user4_6: "BYJU'S에서의 비즈니스 개발 보조: - 2020년부터 2021년까지 근무.",
      user4_7: '- 100억 달러 가치의 유니콘 스타트업에서 핵심 역할을 수행하며 성장을 촉진하고 파트너십을 구축하며 교육 기술 프로그램의 운영을 간소화했습니다.',
      user4_8: 'Space Inn의 공동 창립자: - 창업 기술, 전략적 사고 및 비즈니스 감각 개발에 중점을 둔 독립 프로젝트 공동 창립.',
      user4_9: '주식 시장 투자/거래: - 주식 시장에서 투자 및 거래 경험 5년, 금융 시장에서 투자 기회를 활용하고 탐색하는 전문 지식을 보여줌.',
      user4_10: '기업 회사에서의 인턴십: - 여러 기업 회사에서 여러 차례 인턴십을 완료하여 기업 문화, 효율적인 프로세스 및 효과적인 비즈니스 전략에 대한 귀중한 통찰력을 얻었습니다.',
      user4_11: '암호화폐 업계 경험: - 여러 암호화폐 및 NFT 프로젝트에 참여하여 귀중한 업계 경험을 얻었습니다.',
      user4_12: '- Cardano 프로젝트 Empowa에 기여하여 그 발전과 성장을 촉진했습니다. 2022년 Cardano 프로젝트 Empowa에 기여하여 성장을 촉진했습니다.',
      user4_13: '- 현재 DeepLink Cloud와 협력하여 암호화 분야의 전문 지식을 확장하고 있습니다.',

      user5_1: '2012년부터 현진 ICT에서 부사장으로 근무하고 있으며, 이 회사는 사물인터넷 및 통신 서비스 회사로 11년간 근무했습니다.',
      user5_2: '공공 기관에서 여러 대형 프로젝트를 수행한 경험이 있는 판매 및 시장 관리.',
      user5_3: '한국 최대 IT 회사인 삼성 SDS에서 정보 전략 컨설팅, 비즈니스 혁신 프로젝트 및 소프트웨어 개발을 담당하며 14년(1998-2012) 동안 근무했습니다.',
      user5_4: '2017-2019년까지 3년간 Coinzest 암호화폐 거래소에서 근무했으며, 한국 1위, 세계 10위의 경험을 가지고 있습니다.',
      user5_5: 'Hyunjin Venus의 창립자로, 한국의 대기업 및 공공 기관을 위한 복지 쇼핑몰을 운영하고 있으며, 운영 기간은 5년입니다.',
      user5_6: 'PMP(프로젝트 관리 전문가) 및 CISA(등록 정보 시스템 감사사) 자격증 보유.',
      
      user6_1: '교육 배경: 2003년 베이징 대학 광화 경영학원',
      user6_2: '2023년: MetABC의 최고경영자',
      user6_3: '2020년: HillstoneHub의 최고경영자',
      user6_4: '2019년: Hillstone Business Center의 최고경영자',
      user6_5: '2019년: Hillstone Global(블록체인)의 최고운영책임자',
      user6_6: '2017년: Foundersbridge의 창립자',
      user6_7: '2017년: Hillstone Partners(프라이빗 에퀴티) 파트너',
      user6_8: '2014년: BrightA Consulting의 공동 창립자',
      user6_9: '2012년: 고급 고문(중국 비즈니스 전문가)',

      user7_1: 'Joseph Alexander는 Deep Brain Chain에 맞춤화된 대규모 언어 모델 ChatDBC.com의 창시자입니다.',
      user7_2: '그의 전문 분야는 DeepLink와 DBC의 북미 사업 개발입니다.',
      user7_3: "Twitter에서 {'@'}ChatDBC를 팔로우하여 DeepLink와 DBC의 모든 최신 소식을 받아보세요.",
      
      user8_1: 'Maryna Barysheva는 수상 경력이 있는 Web3 마케팅 에이전시 LKI Consulting의 최고 운영 책임자로, 딥 테크, DeFi, 인프라 및 게임 프로젝트를 전문으로 합니다.',
      user8_2: '그녀는 23개 국가에서 블록체인 프로젝트를 성공적으로 확장하였고, 100만에서 1000만 달러의 마케팅 예산을 관리하며 1억 달러 규모의 B2B 기술 스타트업의 마케팅을 담당했습니다.',
      user8_3: 'Maryna의 전문 분야는 커뮤니티 개발, 브랜드 인지도 및 글로벌 GTM 전략입니다.',
      user8_4: '그녀는 국제적인 공개 연설가이자 핀란드 헬싱키 대학의 인공지능, 기술 및 블록체인 프로젝트 인큐베이터 멘토입니다.',
      
      user9_1: 'Brando는 암호화폐 분야의 베테랑입니다. 2016년에 암호화폐 연구를 시작하여 유명한 2017년 황소시장을 초래했습니다.',
      user9_2: '그는 2012년에 Bigger Than Race™️를 창립하고 2017년에 오늘날의 모습으로 이름을 변경했습니다.',
      user9_3: '그의 자격은 EveryRealm에서 받은 Web3/Metaverse 인증과, Metaverse의 혁신과 개발을 리드하는 벤처 캐피탈이자 IBM에서 받은 블록체인 및 비트코인 인증을 포함합니다.',
      user9_4: '암호화 천재 BRANDO MURPHY',
      user9_5: '그의 주요 비전과 목표는 사람들이 블록체인 기술을 통해 재정적 자유를 실현하고, 제4차 및 제5차 산업 혁명의 새로운 경제 인프라에 투자하고 소유할 수 있도록 돕는 것입니다. BTR 커뮤니티 VC는 전 세계 충성 회원들이 미래의 기술을 소유할 수 있도록 돕고 있습니다.',
      user9_6: '2024년, 그는 DeepLink Protocol의 공동 창립자 겸 최고 마케팅 책임자가 되었으며, DeepLink Protocol은 전 세계 게이머들에게 공정한 경쟁 환경을 제공하는 인공 지능 클라우드 게이밍 기술입니다. 그의 고향은 캘리포니아주 로스앤젤레스입니다.',
      
      user10_1: 'Summer는 미디어 회사 FireHustle의 창립자이자 호스트로, 암호화폐 프로젝트, 투자 전략 등을 다룹니다. Summer는 비즈니스 교육 배경을 가지고 있으며 방송 및 통신 분야에서 풍부한 경험을 가지고 있습니다.',
      user10_2: '미디어 분야에서 그의 포트폴리오에는 44,000명 이상의 구독자를 보유한 YouTube 채널, 3,000명 이상의 회원을 보유한 전용 Discord 커뮤니티, 개인 멤버 그룹 등이 포함됩니다.',
      user10_3: '투자 측면에서 FireHustle은 개인적으로 50개 이상의 web3 스타트업에 투자했으며, 많은 암호화폐 프로젝트, 벤처 캐피탈, 스타트업 플랫폼, 신디케이트, KOL 그룹 등의 마케팅, 자금 조달 및 컨설팅 파트너로 활동하고 있습니다.',
      
      user11_1: 'Manny는 10년 이상의 업계 경험을 가진 블록체인 애호가이자 전문가로, 2013년부터 선도적인 회사와 계획에 기여해 왔습니다.',
      user11_2: '그의 경력 배경에는 Grayscale의 기본 위치, Coinbase의 운영 팀, DYDX 및 Ledger의 마케팅 팀, 그리고 마지막으로 Binance US에서의 직위가 포함됩니다.',
      user11_3: "교육: 미국의 저명한 기관(특히 스탠포드 대학)에서 블록체인 및 암호화폐 관련 과정을 이수하였습니다. 과정에는 '블록체인 및 암호화폐: 종합 소개', '고급 블록체인 전략', 'CEO 프로그램' 및 'CMO 프로그램'이 포함됩니다. 그는 금융 및 경영학 전공입니다."
    },
    cont14: {
      title: '우리의 투자 기관',
      organ1: 'DeepBrain Chain 재단은 2017년에 설립되었습니다. 2018년 1월에 DBC 토큰을 Huobi 거래소에 상장하였으며, 2021년에 메인넷을 시작하였습니다. DeepBrain Chain은 GPU를 핵심으로 하는 고성능 분산 컴퓨팅 네트워크입니다. 그 비전은 블록체인 기술을 기반으로 무한히 확장 가능한 분산 고성능 컴퓨팅 네트워크를 구축하여 AI+메타버스 시대의 가장 중요한 컴퓨팅 인프라가 되는 것입니다. 주로 인공지능, 클라우드 게임, 영화 렌더링 등의 분야에 GPU 컴퓨팅 서비스를 제공합니다. 현재 수백 개의 인공지능 및 클라우드 게임 관련 회사와 30,000명 이상의 AI 실무자에게 서비스를 제공하고 있습니다.',
      organ2: 'Gobi Capital은 상하이, 베이징 및 동남아시아에 본사를 둔 전문 벤처 캐피탈 회사로, 중국 초기 기술 프로젝트에 대한 투자를 전문으로 합니다. Gobi 펀드의 전략적 투자자로는 IBM, Sierra Ventures, McGraw-Hill 및 Steamboat Ventures(디즈니의 벤처 캐피탈 부서)가 포함됩니다. Gobi는 Tuniu, Camera360 및 Zhu Yun과 같은 잘 알려진 스타트업에 투자했습니다.',
      organ3: 'Hycons는 DBC 기술을 기반으로 개발된 GPU 컴퓨팅 클라우드 플랫폼으로, Hyunjin ICT에 의해 운영됩니다. Hycons는 DBC 기반의 분산 고성능 네트워크 기술과 DeepLink 기반의 무지연 고화질 스트리밍 기술로 두각을 나타내고 있습니다. 이 플랫폼은 기업, 학교, 연구 기관 및 프랜차이즈 운영자에게 회원 관리, 등록 및 결제 등 포괄적인 서비스를 제공합니다. 현재 한국에서 1,000개 이상의 GPU 카드와 2개 센터를 운영 중이며, 향후 5개 센터로 확장할 계획입니다. 또한 Hycons는 인터넷 카페 프랜차이즈를 통한 사업 응용을 테스트하고 있습니다. Hycons는 높은 기술력과 경쟁력 있는 클라우드 서비스 요금, 자동화된 서비스 역량을 바탕으로 세계 최고의 GPU 컴퓨팅 플랫폼이 되는 것을 목표로 합니다.',
      organ4: 'ROCK Capital은 2018년에 설립된 전문 암호화폐 펀드 회사로, 한국에 본사를 두고 있으며 금융 투자 및 블록체인, 탈중앙화 전문가들의 자원을 모아 다양한 국내외 기업, 글로벌 프로젝트, 정부, 기관 및 기타 경제 주체와 협력하며 100개 이상의 프로젝트를 성공적으로 실행했습니다. ROCK은 협력, 성장, 활성화 및 파트너십을 강화하여 다양한 시장 환경에서 풍부한 경험과 전문 지식을 보유하고 있습니다. ROCK은 굳건한 원칙을 바탕으로 높은 수익을 창출하고 투자 기회를 확대하며 자본 활동을 극대화하여 고객 경험을 창출하고자 합니다.',
      organ5: '이 벤처 캐피탈 회사는 신생 기술에 중점을 둔 탈중앙화 블록체인을 통해 제4차 및 제5차 혁명을 촉진할 것입니다. 다양한 산업에서 얻은 풍부한 지식을 보유하고 있어 전체 Web3 공간과 그 밖의 영역에서 기회를 포착하는 데 도움이 됩니다. 이들은 Bloktopia, Animoca Brands, Stripe와 같은 유명 브랜드와 공동 투자하며 Singularity 네트워크와 Shiba 생태계 및 다양한 게임 스튜디오와 견고한 관계를 구축했습니다. BTR-Army 패스를 소지한 사람들은 이 회사의 서비스를 이용할 수 있습니다.'
    },
    cont15: {
      title: '커뮤니티 활동',
      
    },
    "cont19": {
        "title": "투자자",
        "text": "기술 및 Web3 분야의 존경받는 투자자들이 우리에게 한계를 뛰어넘고 미래를 재정의할 수 있는 힘을 제공합니다."
    },
    "cont20": {
        "title": "주요 파트너",
        "text": "우리의 전략적 제휴 네트워크는 깊이 있는 전문성과 대담한 사고를 바탕으로 기술 및 Web3 분야에서의 영향을 가속화합니다."
    }
  },

  homeNew: {
    cont1:  {
      text1: '원격 게이밍을 재정의 하다.',
      text2: '지연 없는 원격 제어, 딥링크에서는 현실입니다.',
      text3: '현재 윈도우 환경에서만 이용 가능합니다.'
    },
    cont2: {
      title: '원격 제어 소개',
      block1: {
        title: '원격제어란?',
        text: '떨어져 있는 다른 위치의 컴퓨터나 장치를 인터넷을 통해 제어하는 작업입니다.'
      },
      block2: {
        title: '원격제어의 유형',
        text: '원격제어는 클라이언트/서버 기반 원격제어와 P2P 기반 원격제어로 나뉩니다.'
      },
      block3: {
        title: '원격제어의 기능',
        text: '여러 컴퓨터간의 파일 공유, 원격 교육 보안 지원 등 다양한 기능을 수행할 수 있습니다.'
      },
      block4: {
        title1: '딥링크',
        title2: ' 원격제어의 장점',
        text: '블록체인 기술을 통한 분산 문제 해결을 통해 E스포츠급 초 저 지연 연결로 끊김 없는 최적의 게이밍 환경을 제공합니다.'
      }
    },
    cont3: {
      title: '단순 업무조차 어려웠던 기존 원격제어',
      text1: '연결 지연',
      text2: '잦은 끊김',
      text3: '프레임 드랍',
      text4: '비싼 사용료'
    },
    cont4: {
      title1: '이제는 로컬 장치를 제어하듯',
      title2: '매끄럽게!',
      desc: '스마트 컨트랙트 기술을 통한 분산 문제 해결로 빠르고 강력한 원격 제어를 경험해보세요.',
      text1: '간단하고 빠른 접속',
      text2: '8K 지원',
      text3: '고사향 게임 최적화',
      text4: '끊김, 지연X',
      text5: '업무 툴 최적화',
      text6: '무료 이용'
    },
    cont5: {
      title1: '강력하고 매끄러운',
      title2: '원격 게이밍',
      title3: '이제는 언제 어디서든 가능합니다',
      text1: '배틀필드',
      text2: '리그오브 레전드',
      text3: '발로란트'
    },
    cont6: {
      title1: '무겁고 비싼 게이밍 노트북',
      title2: '딥링크는',
      title3: '가볍고 경제적',
      title4: '입니다.',
      text1: '최소 260만원 이상',
      text2: '최소 1.6Kg 이상',
      text3: '고성능 노트북',
      text4: '0 원'
    },
    cont7: {
      title1: '여러 가지 방법으로',
      title2: '다양하게 활용이 가능합니다',
      block1: {
        text1: '외부',
        text2: '업무용',
        text3: '노트북'
      },
      block2: {
        text1: '사무실, 집',
        text2: '고사양',
        text3: '데스크탑',
        text4: '고사양 작업',
        text5: '고사양 게이밍'
      },
      block3: {
        text1: '고객사',
        text2: '공동 작업',
        text3: '원격 업무 수행'
      },
      title3: '강력한 기술력만큼',
      title4: '완벽한 보안을 지원합니다',
      block4: {
        text1: '계정 보안',
        text2: '블록체인 기술 기반의',
        text3: '다중 계정 보호 메커니즘'
      },
      block5: {
        text1: '연결 보안',
        text2: 'DTLS1.2 프로토콜 보호'
      },
      block6: {
        text1: '데이터 보안',
        text2: '256비트 AES 암호화'
      },
      block7: {
        text1: '설비 보안',
        text2: '사용자 권한 설정 가능'
      }
    },
    cont8: {
      title1: '딥링크로',
      title2: '어디서든 연결하세요',
      text1: '노드가 있는 곳이라면 어디서든 고사양 게임을 원격 제어로 끊김 없이 플레이 가능합니다. 국가, 장소에 상관없이 블록체인 노드 컴퓨터를 연결하여 고사양 온라인 게임을 즐길 수 있습니다',
      title3: '딥링크로 유연하게',
      title4: '협업하세요',
      text2: '딥링크의 초저지연 원격 연결을 통해 보다 강력한 협업이 가능합니다. 디자이너, 기획자, 개발자 모두에게 최적화되어 있습니다'
    },
    cont9: {
      title1: '게이밍 뿐만 아닙니다.',
      title2: '작업자를 위한 생산성 도구까지 지원합니다.'
    },
    cont10: {
      title1: '딥링크와 함께',
      title2: '공간적, 기술적 제약에서 해방되세요.',
      block1: {
        name: '조정현님',
        desc: 'IT운영 및 원격 유지 보수 담당자',
        text1: '지연이 없이 원격 업무 지원이 가능해',
        text2: '고객사 업무 만족도가 향상되었습니다'
      },
      block2: {
        name: '백상우님',
        desc: '게이머',
        text1: '이제는 영업용 노트북만 있어도  출장중에 고성능 게이밍이 가능합니다',
        text2: '상상도 못했던 일이죠'
      },
      block3: {
        name: '김준호님',
        desc: '영업담당자 ',
        text1: '기기 제약이 없어',
        text2: '노트북만 휴대해도 영업이 가능해졌습니다'
      },
      block4: {
        name: '유민경님',
        desc: '원격 근무 디자이너',
        text1: '언제 어디서든 다운로드 없이',
        text2: '본사 컴퓨터 접근을 통한 업무가 가능해졌어요!'
      },
    },
    cont11: {
      title: '딥링크 지갑(Wallet)이란',
      text1: '안전한 계정 보호 시스템',
      desc1: '딥링크 지갑은 블록체인 기술과 256비트 AES 암호화 및 디바이스 정보 기반 다층 계정 보호 메커니즘으로 강력하고 안전한 계정 보호 시스템을 지원합니다.',
      text2: '안전한 토큰 거래 및 활용',
      desc2: '지갑을 통해 스마트 컨트랙트, P2P 암호화 전송을 통한 거래보다 안전한 토큰 거래 및 활용이 가능합니다.',
      text3: '전 세계 어디서든 딥링크 사용 가능',
      desc3: '지갑 개인키만 있다면 전 세계 어디서든 개인 지갑에 로그인하여 딥링크를 통한 게임플레이가 가능합니다'
    },
    cont12: {
      title1: '초 저지연 원격 게이밍',
      title2: '딥링크로 지금 시작하세요!',
      text1_1: '딥링크 클라이언트',
      text1_2: '를 설치해주세요',
      text2_1: '딥링크 회원가입을 위한',
      text2_2: '개인 지갑을 생성',
      text2_3: '해주세요.',
      text3_1: '지갑 생성 후',
      text3_2: '원하는 기기에 접속하여 딥링크를 실행',
      text3_3: '합니다'
    }
  },
  cloudInternet: {
    title: '클라우드 인터넷 카페 솔루션 제공자로의 전환 방법',
    text: '현재 전 세계적으로 주요 인터넷 카페 분포 국가는 한국 (6,000), 중국 (40,000), 브라질 (10,000), 인도네시아 (20,000), 필리핀 (20,000), 베트남 (15,000), 터키 (5,000) 등입니다. 또한, 인도, 멕시코, 중동, 남아메리카, 아프리카, 유럽 등의 국가도 있습니다. 전 세계적으로 15 만 개 이상의 인터넷 카페가 있습니다. 그러나 인터넷 카페 산업은 20 년의 역사를 가지고 전통 산업이 되었습니다. 회수 기간은 3-4 년으로 증가하고 다양한 위험에 직면하고 있습니다. 예를 들어 COVID-19 팬데믹으로 많은 인터넷 카페가 폐쇄되었습니다. 따라서 인터넷 카페 산업은 회수 기간을 단축하고 위험을 감소시키며 운영 효율을 향상시킬 새로운 솔루션이 필요합니다. 이렇게하여 클라우드 인터넷 카페 솔루션이 탄생합니다.',
    cont2: {
      text1: '무엇',
      text2: '클라우드 PC방 솔루션?',
      text3: '클라우드 사이버 카페 솔루션은 초저지연 렌더링 기술을 갖춘 새로운 유형의 사이버 카페 서비스 모델입니다. 클라우드 사이버 카페에서는 게임 및 응용 프로그램이 로컬 컴퓨터에 직접 설치되지 않고 클라우드 GPU 서버에 호스팅됩니다. 사용자는 고성능 로컬 하드웨어가 필요 없이 클라우드 서비스를 통해 게임에 액세스하고 플레이할 수 있습니다.',
      block1: {
        text1: '로컬 하드웨어 요구 사항 줄이기',
        text2: '게임 및 응용 프로그램이 클라우드 GPU 서버에서 실행되므로 로컬 컴퓨터에 고성능 GPU 또는 대용량 저장 공간이 필요하지 않습니다. 이는 사이버 카페 하드웨어 투자 및 유지 관리 비용을 줄입니다.'
      },
      block2: {
        text1: '최신 게임 즉시 액세스',
        text2: '사용자는 다운로드 및 설치를 기다릴 필요없이 최신 게임에 즉시 액세스할 수 있습니다. 모든 게임 업데이트 및 유지 관리는 클라우드에서 이루어지므로 사용자에게 편리하고 효율적인 경험을 제공합니다.'
      },
      block3: {
        text1: '고성능 게임 경험',
        text2: '로컬 모니터는 미니 PC (약 100 달러)로 구성되어 있으며 클라우드 사이버 카페에서도 고성능 게임 경험을 제공할 수 있습니다. 이는 게임 계산이 원격 고성능 GPU 서버에서 이루어지기 때문입니다.'
      },
      block4: {
        text1: '공간 및 에너지 절약',
        text2: '낮은 구성의 로컬 컴퓨터는 더 적은 공간을 차지하며 더 적은 전력을 소비하여 사이버 카페 운영 비용을 절감하는 데 도움이 됩니다.'
      },
      block5: {
        text1: '쉬운 관리 및 유지 관리',
        text2: '사이버 카페 관리자는 모든 콘텐츠가 클라우드에서 중앙 집중식으로 관리되기 때문에 소프트웨어 및 게임을보다 쉽게 관리할 수 있습니다. 이로 인해 로컬 유지 관리 작업 및 잠재적 인 기술 문제가 줄어듭니다.'
      }
    },
    cont3: {
      text1: '한국의 Glowsteam 클라우드 사이버 카페',
      text2: '클라우드 사이버 카페의 실제 운영 환경'
    },
    cont4: {
      text1: '클라우드 사이버 카페 솔루션은 사이버 카페 투자자를 돕는 데 도움이 될 수 있습니다',
      text2: '순이익을 3배로 늘리고 투자 회수 기간을 40% 단축합니다',
      text3: '비교 데이터를 다운로드하려면 클릭하세요'
    },  
    cont5: {
      text1: '클라우드 사이버 카페 솔루션 제공업체의 수익은 어떻게 발생하나요?',
      text2: '수익이 사이버 카페 기기 당 수입의 12%로 계산되면, 총 1000 개의 사이버 카페에 10 만 개의 기기를 서비스하고, 각 기기가 하루 평균 10 시간 사용되며, 기기 당 시간당 평균 비용이 0.8 달러 인 경우 12%는 약 0.1 달러입니다.',
      text3: '클라우드 사이버 카페 솔루션 제공업체의 연간 수익은',
      text4: '100,000 * 10 * 0.1 * 365 = 3,650 만 달러',
      text5: '클라우드 사이버 카페 솔루션 제공업체가 되는 방법',
      text6: 'DeepBrainChain 및 DeepLink 기술을 기반으로 한 한국의 HYCONS가 완전한 클라우드 사이버 카페 솔루션을 개발했습니다. DeepBrainChain은 GPU 컴퓨팅 파워를 제공하고, DeepLink는 초저 지연 렌더링 기술을 제공하며, HYCONS는 전체 솔루션을 제공합니다. 유사하게, DeepBrainChain은 엔진을 제공하고, DeepLink는 자율 주행 시스템을 제공하며, HYCONS는 완전한 차량 솔루션을 제공합니다. 다양한 국가의 로컬 솔루션 제공 업체는 로컬 결제 시스템을 HYCONS에 통합하기만 하면 현지 사이버 카페에 서비스를 제공할 수 있으며, 솔루션 제공 업체는 자체 브랜드를 설정할 수 있습니다. HYCONS는 브랜드를 노출하지 않고 순수한 기술 서비스 제공자로만 기능합니다.',
      text7: '자세한 정보는 문의하십시오 :',
      text8: "service{'@'}deepbrainchain.org"
    },
    cont6: {
      title: '클라우드 사이버 카페 관련 자료 다운로드',
      text1: 'HYCONS 소개',
      text2: '클라우드 사이버 카페 솔루션 수익 분석',
      text3: '클라우드 사이버 카페 데이터 센터 아키텍처 다이어그램',
      text4: '클라우드 사이버 카페 솔루션 테스트 및 설치 매뉴얼',
      text5: 'DeepLink 프로토콜 소개'
    }
  },
  reward2: {
    desc: {
      title1: 'DeepLink 노드',
      text1: '노드 소유자만 CPU 트래픽 채굴 및 개인 GPU 기기 채굴에 참여할 수 있습니다. 총 보상은 DLC 토큰의 12%이며, 그 중 4%는 CPU 트래픽 노드, 8%는 개인 GPU 노드에 할당됩니다. 토큰 총 수량은 120억입니다. 각 노드의 출력은 실제로 판매된 노드 수와 관련이 있습니다. 모두 판매될 경우 각 노드는 10만 DLC를 생산하며, 노드의 10%만 판매될 경우 각 노드는 100만 DLC를 생산합니다.',
      title2: '총 노드 수: 120,000 추가 발행 없음',
      text2: '각 노드마다 추가 에어드롭 제공',
      text3: '각 노드에서 채굴되는 최소 DLC 수량',
      text4: '초기 가격',
      text5: '최고 가격'
    },
    btn_text: '노드 구매',
    table: {
      tier: '계층',
      license: '노드 라이센스 가격 (USDT)',
      total_num: '총 라이선스',
      total_usdt: '총 USDT',
      buy: '구매'
    },
    intro: {
      title: '노드 마이닝 소개',
      text1_1: 'DeepLink의 네트워크 사용자가 많을수록 더 많은 네트워크 트래픽과 계산 능력이 필요하며, 이를 위해 DeepLink 네트워크에 인프라를 제공하기 위해 더 많은 CPU 트래픽 머신과 GPU 계산 머신이 필요합니다. 이를 통해 분산화가 이루어지고 DeepLink의 성장이 더욱 빨라집니다. 노드 권한을 구매하면 마이닝에 참여하여 보상을 받을 기회를 얻을 수 있습니다. 질문이 있으신가요? 위에서',
      text1_2: '와',
      text1_3: '함께 대화하세요.',
      text2: '각 노드는 NFT 형태로 발행되며, 추가로 DBC EVM 체인에서 30,000 DLC 에어드롭을 받을 수 있습니다. 에어드롭된 DLC의 20%는 즉시 잠금 해제되며, 80%는 8개월 동안 점진적으로 해제됩니다.\n(DLC 토큰을 확인하는 방법: 지갑을 열고 DBC EVM 네트워크를 선택한 후, 토큰 추가를 선택하고 계약 주소를 입력하세요: 0x6f8F70C74FE7d7a61C8EAC0f35A4Ba39a51E1BEe)',
      text3: '노드는 2024년 10월 31일 이전에 타인에게 재판매할 수 없습니다.',
      text4: '채굴된 토큰의 25%는 즉시 잠금 해제되며, 나머지 75%는 150일에 걸쳐 선형적으로 잠금 해제됩니다.',
      text5: '상장 후 10일 이내에 마이닝이 시작되지 않을 경우, 보상은 에어드롭을 통해 배포되며, 에어드롭은 7일마다 발생합니다. 에어드롭 규칙은 마이닝 출력 규칙과 동일하며, 하루 총 출력량은 410만 DLC로, 판매된 노드 수에 따라 균등하게 배분됩니다. 25%는 즉시 잠금 해제되고, 나머지 75%는 150일 동안 선형적으로 잠금 해제됩니다. 마이닝이 이미 시작된 경우 보상을 받으려면 해당 기계가 필요합니다.',
      text6: '20개 이상의 노드를 구매하는 경우 노드 운영을 위임할 수 있습니다. 개별적으로 1,000개 이상의 노드를 구매하는 투자자에게는 노드 운영을 위한 전체 지원이 제공되며, 노드 운영을 돕기 위해 협력 운영자와 도킹할 수 있는 기능도 제공됩니다.',
      text7: '하나의 가상 머신은 20개의 NFT 노드를 지원할 수 있으며, 수입은 20배로 증가합니다.',
      text8: 'DeepLink 노드를 구매하고 900만 달러 규모의 Orion GPU 계산 능력 대회에 참여하세요: '
    },
    question: {
      ques1: '총 몇 개의 노드 NFT가 판매됩니까?',
      ans1: '총 12만 개',
      ques2: '각 노드 NFT의 비용은 얼마입니까?',
      ans2: '10단계로 나누어져 있으며, 가격은 30U에서 155U까지 다양하며, 공식 웹사이트에서 판매되며 매진될 때까지 판매됩니다',
      ques3: '노드 NFT 구매는 일회성 결제입니까?',
      ans3: '예, 노드 구매는 일회성 결제이며, 이후 추가 비용은 발생하지 않습니다',
      ques4: '노드 NFT 구매 시 추가 에어드롭이 있습니까?',
      ans4: '네, 각 노드 소유자는 30,000 DLC의 에어드롭을 받습니다. 에어드롭된 DLC의 20%는 즉시 해제되며, 80%는 8개월 동안 점진적으로 해제됩니다',
      ques5: '구체적인 마이닝 메커니즘은 무엇입니까?',
      ans5: '총 보상은 120억 DLC입니다. 트래픽 노드의 총 수익은 40억 DLC이며, 개인 GPU 머신 노드의 총 수익은 80억 DLC입니다. 노드 NFT를 구매하면 이 두 가지 마이닝에 동시에 참여할 수 있습니다. 첫 4년간의 보상은 연간 15억 DLC이며, 4년마다 절반으로 줄어듭니다. 일일 총 보상은 411만 DLC이며, 노드 NFT 소유자에게만 분배됩니다. 채굴된 토큰의 25%는 즉시 잠금 해제되며, 나머지 75%는 150일 동안 선형적으로 잠금 해제됩니다',
      ques6: '구매한 노드 NFT가 CPU 트래픽 머신과 개인 GPU 머신 마이닝에 동시에 참여할 수 있습니까?',
      ans6: '예',
      ques7: 'CPU 대역폭 트래픽 머신 노드는 무엇입니까?',
      ans7: 'CPU 대역폭 트래픽 머신 노드는 DeepLink 네트워크의 계산 및 트래픽 전달의 핵심 역할을 담당합니다. 마이닝 모델을 통해 DeepLink는 더 많은 사람들이 참여하고 수익을 얻을 수 있도록 합니다. 트래픽 노드의 총 수익은 40억 DLC입니다',
      ques8: '첫 해의 대역폭 트래픽 머신에 대한 총 보상은 얼마입니까?',
      ans8: '첫 해 보상은 5억 DLC이며, 매일 약 137만 DLC가 보상됩니다',
      ques9: '각 CPU 대역폭 트래픽 머신 노드의 수익과 대역폭의 관계는?',
      ans9: '기본 대역폭은 10Mb 업링크 대역폭입니다. 대역폭 값은 10Mb 업링크 대역폭의 경우 10입니다. 대역폭은 10의 배수로만 유효하며, 예를 들어 45Mb 업링크 대역폭은 대역폭 값으로 40으로 계산됩니다. 대역폭은 실제 사용에 따라 30% 증가합니다. 마지막으로 실제 대역폭 값에 따라 수익이 나누어집니다',
      ques10: '대역폭 트래픽 머신 노드의 구성 요구 사항이 높습니까? 구성 정보가 있습니까? 마이닝에 참여하는 방법은 무엇입니까?',
      ans10: '구성 요구 사항이 높지 않습니다. vCPU 1C + 2G 메모리 + 30G 하드 디스크가 필요합니다. AWS에서 렌탈 비용은 약 10 USD/월이며, 대역폭은 실제 사용량에 따라 과금됩니다. AWS에서 CPU 가상 머신을 렌탈한 후 DeepLink 트래픽 노드 마이닝 프로그램을 설치하고, 지갑 주소를 생성하고, 노드 NFT를 지갑으로 전송한 후 마이닝 기능을 활성화하여 마이닝에 참여할 수 있습니다',
      ques11: '개인 GPU 머신 노드는 무엇입니까?',
      ans11: '개인은 자신의 GPU 컴퓨터를 제공하여 마이닝에 참여할 수 있습니다. GPU 머신은 DeepLink 프로토콜에 클라우드 게임 인프라 서비스를 제공합니다. 개인 GPU 머신 노드의 총 수익은 80억 DLC입니다',
      ques12: '첫 해 개인 GPU 머신 노드의 총 보상은 얼마입니까?',
      ans12: '첫 해 보상은 10억 DLC이며, 매일 약 274만 DLC가 보상됩니다',
      ques13: '개인 GPU 머신 노드의 구성 요구 사항은 무엇입니까? 마이닝에 참여하는 방법은?',
      ans13: 'GPU 머신 구성 요구 사항은 다음과 같습니다: CPU 주파수 최소 3.0GHz, GPU는 Nvidia Geforce 시리즈: 20xx, 30xx, 40xx, 메모리 최소 16G'
    }
  },
  BandWidth: {
    "title": "트래픽 대역폭 마이닝 규칙",
    "text1": "트래픽 대역폭 마이닝은 총 40억 DLC의 보상이 있으며, 처음 4년 동안 매년 5억 DLC의 보상이 주어지며, 4년마다 반으로 줄어듭니다.",
    "cont2": {
      "title": "참여 규칙",
      "text1_1": "노드 NFT 소유가 필요합니다(각 노드는 30,000 DLC의 에어드롭을 제공하며. 노드에 대해 자세히 알아보세요: ",
      "text1_2": "). 하나의 CPU 기계는 여러 노드를 소유할 수 있으며, 보상 토큰 수는 노드 수에 비례합니다. 하나의 기계가 소유할 수 있는 노드 NFT의 최대 수는 20개입니다.",
      "text2_1": "CPU 서버의 최소 요구 사항: \n 컴퓨팅 서버의 각 매개변수는 최소 요구 사항을 충족해야 합니다",
      "text3_1": "CPU",
      "text3_2": "vCPU 1C",
      "text4_1": "메모리",
      "text4_2": "2G",
      "text5_1": "하드 드라이브：",
      "text5_2": "30G 하드 드라이브",
      "text6_1": "네트워크 대역폭：",
      "text6_2": "기본 대역폭은 10Mb 업로드 대역폭입니다; 대역폭이 200M 증가할 때마다 CPU 1C, 메모리 2G를 추가하는 것이 좋습니다"
    },
    cont3: {
      title: '트래픽 마이닝 지역 규칙',
      text: '트래픽 마이닝은 전 세계를 약 130개 지역으로 나누며, 각 지역에 다른 DLC 총 보상이 할당됩니다. 지역 보상 공식은 다음과 같습니다: 지역 보상 = 지역 값 * 일일 보상 / 총 지역 값',
      text1: '지역 목록은 다음과 같습니다:',
      text2: '아시아',
      text3: '아메리카',
      text4: '유럽',
      text5: '아프리카',
      text6: '오세아니아',
      li_other: '기타',
      li1: '중국',
      li1_1: '화북 지역',
      li1_2: '동북 지역',
      li1_3: '화동 지역',
      li1_4: '화중 지역',
      li1_5: '화남 지역',
      li1_6: '서남 지역',
      li1_7: '서북 지역',
      li1_8: '대만, 중국',
      li1_9: '홍콩, 중국',
      li2: '인도',
      li2_1: '우타르프라데시 주',
      li2_2: '마하라슈트라 주',
      li2_3: '비하르 주',
      li2_4: '인도네시아',
      li2_5: '파키스탄',
      li2_6: '방글라데시',
      li2_7: '일본',
      li2_8: '필리핀',
      li2_9: '베트남',
      li2_10: '터키',
      li2_11: '태국',
      li2_12: '한국',
      li2_13: '말레이시아',
      li2_14: '사우디아라비아',
      li2_15: '아랍에미리트',

      li3: '미국',
      li3_1: '캘리포니아주',
      li3_2: '텍사스주',
      li3_3: '플로리다주',
      li3_4: '뉴욕주',
      li3_5: '펜실베이니아주',
      li3_6: '일리노이주',
      li3_7: '오하이오주',
      li3_8: '조지아주',
      li3_9: '미시간주',
      li3_10: '노스캐롤라이나주',
      li3_11: '미국의 기타 지역',
      
      li4_1: '멕시코',
      li4_2: '캐나다',
      li4_3: '브라질',
      li4_4: '콜롬비아',
      li4_5: '아르헨티나',
      
      li5: '러시아',
      li5_1: '모스크바',
      li5_2: '상트페테르부르크',
      li5_3: '러시아의 기타 지역',
      li5_4: '독일',
      li5_5: '영국',
      li5_6: '프랑스',
      li5_7: '이탈리아',
      li5_8: '스페인',
      li5_9: '네덜란드',
      li5_10: '스위스',
      
      li6_1: '나이지리아',
      li6_2: '이집트',
      li6_3: '남아프리카 공화국',
      
      li7_1: '오스트레일리아',
    },
    cont5: {
      title: '보상 메커니즘 (DBC AI 공용 체인의 EVM 호환 DLC 기반, 브라우저 URL: https://dbcscan.io/)',
      text1: '첫 4년 동안의 채굴 보상은 연간 5억 DLC로, 매일 136.9만 DLC입니다. 각 지역은 가중치 비율에 따라 DLC 보상이 할당됩니다. 특정 지역에 기계가 없으면 해당 지역의 DLC 보상은 자동으로 소멸되어 아무에게도 할당되지 않습니다.',
      text2: '이 시스템에서 각 지역마다 매일 할당되는 총 토큰 수는 고정되어 있습니다. 기계가 받는 보상은 기계의 대역폭, 유효 채굴 시간 Y, 그리고 스테이크된 토큰 수에 따라 달라집니다. 스테이크된 토큰이 많을수록 보상이 더 많아지지만, 비선형적으로 증가합니다. 0-10,000개 코인까지는 보상 기준이 같으며, 10,000개를 초과하면 함수와의 연관성이 나타납니다.',
      text3: '기계의 트래픽 대역폭이 사용 중인 경우, 계산력 값이 30% 증가하고, 노드 NFT의 수는 계산력 값과 곱셈 관계에 있습니다. 현재 각 기계는 최대 20개의 노드 NFT를 보유할 수 있습니다.',
      text4: '노드 NFT를 소유한 CPU 기계만이 온라인 보상을 받을 수 있습니다.',
      text5: '현재 한 대의 기계는 최대 20개의 노드 NFT를 연결할 수 있으며, NFT 수가 많을수록 보상이 더 많아지며 선형 관계를 보입니다.',
      text6: '첫 300일 동안 한 대의 기계는 최대 20개의 노드 NFT를 연결할 수 있습니다.',
      text7: '첫 600일 동안 한 대의 기계는 최대 10개의 노드 NFT를 연결할 수 있습니다.',
      text8: '600일에서 900일 사이에 각 기계는 최대 5개의 노드 NFT를 연결할 수 있습니다.',
      text9: '900일에서 1200일 사이에 각 기계는 최대 2개의 노드 NFT를 연결할 수 있습니다.',
      text10: '1200일에서 1800일 사이에 각 기계는 최대 1개의 노드 NFT를 연결할 수 있습니다.',
      text11: '1800일 이후 각 노드 NFT는 2대의 기계를 연결할 수 있습니다.',
      text12: '2400일 이후 각 노드 NFT는 4대의 기계를 연결할 수 있습니다.',
      text13: '보상 계산:',
      text14: 'R: 매일의 총 보상 토큰 수 (고정값)',
      text15: 'i번째 기계의 스테이크된 토큰 수',
      text16: 'i번째 기계의 계산력 값',
      text17: 'i번째 기계의 유효 채굴 시간',

      text18: '여기서 N은 채굴에 참여하는 기계의 총 수입니다',
      text19: '10만 코인의 보상은 1만 코인을 스테이킹할 때의 1.23026배로, 약 23.03% 증가합니다',
      text20: '100만 코인의 보상은 1만 코인을 스테이킹할 때의 1.46052배로, 약 46.05% 증가합니다',
      text21: '1000만 코인을 스테이킹할 때의 보상은 1만 코인을 스테이킹할 때의 1.69078배로, 약 69.08% 증가합니다',
      text22: '1억 코인을 스테이킹할 때의 보상은 1만 코인을 스테이킹할 때의 1.92103배로, 약 92.10% 증가합니다',
      
      title1: '보상 청구:',
      text1_1: '보상은 24시간마다 한 번씩 청구할 수 있습니다',
      text1_2: '초기에 DLC를 스테이킹하지 않았거나 스테이킹 금액이 1만 미만인 경우, 수익은 1만 DLC에 도달할 때까지 스테이킹된 DLC로 축적됩니다',
      text1_3: '1) 임대료는 1만 DLC에 도달할 때까지 스테이킹 자본으로 축적됩니다',
      text1_4: '2) 최소 임대 기간은 2시간이며, 최대 임대 시간에는 제한이 없습니다. 사용 가능 시간이 1시간 미만 남았을 때는 기계를 임대할 수 없으며 온라인 보상에 포함되지 않습니다. 채굴자는 언제든지 시간을 연장할 수 있지만, 각 연장은 최소 2시간 이상이어야 합니다',
      text1_5: '보상은 언제든지 출금할 수 있습니다. 출금할 때마다 새로 생성된 보상의 10%는 즉시 출금 가능하며, 90%는 180일 동안 잠깁니다. 잠긴 부분은 출금할 때마다 0.5%씩 출금 가능하며, 180일 후에는 전체 금액을 출금할 수 있습니다',
      
      title2: '기계의 일일 DLC 수익 계산 공식: DLC는 {num}U의 가격으로 계산됩니다,',
      text2_1: '내 CPU 기계의 대역폭:',
      text2_2: '노드 NFT 수:',
      text2_3: '지역:',
      text2_4: '스테이킹된 DLC 수량:',
      text2_5: '총 대역폭:',
      text2_6: '(평균 10개의 NFT, 평균 1만 개의 DLC 스테이킹으로 계산)',
      text2_7: '선택하세요',
      title3: '체인상 보상 수입: {dlc_num} DLC(현재 DLC 가격: {num} USD에서 {usdt_num} USD에 해당)',
      title4: '신고 및 검증 메커니즘 (DBC AI 공용 체인의 EVM 호환 DLC 기반)',
      text4_1: '기계는 항상 DBC AI 공용 체인의 감지 메커니즘을 가지고 있으며, DBC 분산 감지 클러스터에 정보를 보고하여 기계가 온라인 상태인지를 판단합니다. 온라인 상태가 아닐 경우 온라인 보상이 차감됩니다. 사용자 사용 중에 중단이 발생하면 사용자에게서 DLC가 차감됩니다. 벌금 한도:',
      text4_2: '1) 코인을 스테이킹하지 않은 경우, 누적된 보상만 벌금이 부과되며 최대 1만 DLC입니다',
      text4_3: '2) DLC를 스테이킹한 경우, 최대 1만 DLC까지 벌금이 부과됩니다',
    }
  },
  
  coverage: {
    title: 'DeepLink 소프트웨어 글로벌 사용자 분포 지도',
    text: '총 딥링크 사용자 수:'
  },
}